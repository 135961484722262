.sidebar-content {
    width: 100%;
    padding: 40px 40px 40px 40px;
}

.sidebar-TopContent {
    padding: 20px 40px 32px 40px;
}
/* .ant-pagination-item-active{
    border-color: #c4e1aa !important;
} */
.ant-pagination-item-active {
    font-weight: 500;
    background: #fff;
    border: 2px solid #c4e1aa !important;
}

@media (max-width: 991.98px) {
    .sidebar-content {
        width: 100%;
        padding: 40px 15px 40px 15px;
    }
    .sidebar-TopContent {
        width: 100%;
        padding: 20px 15px 32px 15px;
    }
}

.userProfileImage img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
}