/* ============= */


/* ============= */

@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 100;
    src: url('../fonts/Gotham/Gotham-Thin.otf') format("opentype");
}

@font-face {
    font-family: 'Gotham';
    font-style: italic;
    font-weight: 100;
    src: url('../fonts/Gotham/Gotham-ThinItalic.otf') format("opentype");
}

@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 200;
    src: url('../fonts/Gotham/Gotham-XLight.otf') format("opentype");
}

@font-face {
    font-family: 'Gotham';
    font-style: italic;
    font-weight: 200;
    src: url('../fonts/Gotham/Gotham-XLightItalic.otf') format("opentype");
}

@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/Gotham/Gotham-Light.ttf') format("truetype");
}

@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/Gotham/Gotham-LightItalic.ttf') format("truetype");
}

@font-face {
    font-family: 'Gotham';
    font-style: italic;
    font-weight: 300;
    src: url('../fonts/Gotham/Gotham-ThinItalic.otf') format("opentype");
}

@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/Gotham/Gotham-Book.ttf') format("truetype");
}

@font-face {
    font-family: 'Gotham';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/Gotham/Gotham-BookItalic.ttf') format("truetype");
}

@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/Gotham/Gotham-Medium.ttf') format("truetype");
}

@font-face {
    font-family: 'Gotham';
    font-style: italic;
    font-weight: 500;
    src: url('../fonts/Gotham/Gotham-MediumItalic.ttf') format("truetype");
}

@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/Gotham/Gotham-Bold.otf') format("opentype");
}

@font-face {
    font-family: 'Gotham';
    font-style: italic;
    font-weight: 700;
    src: url('../fonts/Gotham/Gotham-BookItalic.ttf') format("truetype");
}

@font-face {
    font-family: 'Gotham';
    font-style: italic;
    font-weight: 900;
    src: url('../fonts/Gotham/Gotham-Black.otf') format("opentype");
}


/* ================================================ */

@font-face {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/Gotham-Rounded/GothamRounded-Light.otf') format("opentype");
}

@font-face {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/Gotham-Rounded/GothamRounded-LightItalic.otf') format("opentype");
}

@font-face {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/Gotham-Rounded/GothamRounded-Book.otf') format("opentype");
}

@font-face {
    font-family: 'Gotham Rounded';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/Gotham-Rounded/GothamRounded-BookItalic.otf') format("opentype");
}

@font-face {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/Gotham-Rounded/GothamRounded-Medium.otf') format("opentype");
}

@font-face {
    font-family: 'Gotham Rounded';
    font-style: italic;
    font-weight: 500;
    src: url('../fonts/Gotham-Rounded/GothamRounded-MediumItalic.otf') format("opentype");
}

@font-face {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/Gotham-Rounded/GothamRounded-Bold.otf') format("opentype");
}

@font-face {
    font-family: 'Gotham Rounded';
    font-style: italic;
    font-weight: 700;
    src: url('../fonts/Gotham-Rounded/GothamRounded-BoldItalic.otf') format("opentype");
}