@import "../../../../assets/css/variables.css";

@media (max-width:1140px) {
    .content-to-hide {
        display: none;
    }
}


/* @media (max-width:1140px) {
    .textMoveDown {
        margin-top: 88px;
    }
}

@media (min-width:1195px) {
    .textMoveUp {
        margin-top: -80px;
    }
} */

.home_searchbottonbox {
    display: flex;
    padding: 0px 0px 0px 16px;
    height: 48px;
    left: 0px;
    margin-top: 40px;
    background: #FFFFFF;
    border: 1px solid #383A48;
    box-shadow: 0px 2px 4px rgba(51, 51, 51, 0.25);
    border-radius: 4px;
}

.seeWhatsUpInPara-box p {
    font-size: 16px;
}


/* .home_searchbutton {
      font-family: 'Gotham Rounded';
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 11px 0px;
    width: 87px;
    background: var(--custom-main-btn-primary);
    border: 1px solid #383A48;
    border-radius: 4px;
    color: white;
} */

.home_searchbutton {
    font-family: 'Gotham Rounded';
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 11px 0px;
    width: 87px;
    background: var(--custom-main-btn-primary);
    border: 1px solid #383A48;
    border-radius: 4px;
    color: var(--custom-main-btn-secondary);
    margin: -1px;
}

.home_inputsearchbox {
    font-family: 'Gotham Rounded';
    font-style: italic;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    color: #999999;
    margin-left: 0px;
    flex: 1;
    align-content: center;
    border: 0px;
    width: 100%;
}

.home_seewhatsupinpara {
    position: static;
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 100%;
    /* or 48px */
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 0px 40px 0px;
    color: #333333;
}