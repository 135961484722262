.checkout-section {
    padding: 45px 0px;
    background: #FAFAFA;
}
.deliveryHide{
display: none;
}
.checkout-boxes {
    font-size: 14px;
    border-radius: 8px;
}

.checkout-boxes {
    border-radius: 8px;
    box-shadow: 0px 3px 8px rgba(102, 102, 102, 0.1);
}

.card-grey {
    background: #FAFAFA;
    border: 0px solid #E6E6E6;
    box-sizing: border-box;
    border-radius: 8px;
}

.card.delivery-addressBox {
    border-radius: 8px;
    border: 1px solid #E6E6E6;
}

.card.delivery-addressBox .card-body {
    padding: 24px 16px;
    border-radius: 8px;
}

.card.add-delivery-addressBox {
    background: #FAFAFA;
    border: 1px dashed #E6E6E6;
    box-sizing: border-box;
    border-radius: 8px;
}

.card.add-delivery-addressBox .card-body {
    padding: 24px 16px;
    border-radius: 8px;
}


/* ================================ */

.delivery-timeBox {
    border: 1px solid #E6E6E6;
    padding: 16px 24px;
    margin-bottom: 8px;
    background: #FAFAFA;
    box-sizing: border-box;
    border-radius: 4px;
}


/* ====================================== */

.Leave-note-box textarea.form-control {
    padding: 16px;
    background-color: #FAFAFA;
}


/* ==== normal collapse color ================================== */

.site-collapse-custom-collapse .site-collapse-custom-panel {
    margin-bottom: 24px;
    overflow: hidden;
    background: #ffffff;
    border: 0px !important;
    border-radius: 12px !important;
}

.site-collapse-custom-collapse .site-collapse-custom-panel .ant-collapse-header .ant-collapse-header-text {
    align-self: center;
    margin-left: 10px;
    font-size: 1.25rem;
    font-weight: 500;
}

.site-collapse-custom-collapse .site-collapse-custom-panel .ant-collapse-header div {
    width: 35px;
    height: 35px;
    border: 3px solid #333333;
    border-radius: 50%;
}

.site-collapse-custom-collapse .site-collapse-custom-panel .ant-collapse-header div .ant-collapse-arrow {
    width: 30px;
    height: 29px;
    padding: 1px;
}

.site-collapse-custom-collapse .site-collapse-custom-panel .ant-collapse-header div.ant-collapse-extra {
    width: auto;
    height: auto;
    align-self: center;
    border: 0px solid #333333;
    border-radius: 0%;
}


/* -- inner collapse --- */

.site-collapse-custom-inner-panel {
    font-size: 16px !important;
}

.site-collapse-custom-inner-collapse .site-collapse-custom-inner-panel {
    margin-bottom: 0px;
    overflow: hidden;
    background: #ffffff;
    border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -ms-border-radius: 0px !important;
    -o-border-radius: 0px !important;
}

.site-collapse-custom-inner-panel .ant-collapse-header {
    padding-left: 0px !important;
}

.site-collapse-custom-inner-panel .ant-collapse-content-box {
    padding: 16px 0px !important;
}

.choose-delivery-time-custom-collapse .ant-collapse-header {
    padding: 0px 16px !important;
}

.choose-delivery-time-custom-collapse .choose-delivery-time-custom-panel {
    border-bottom: 0px !important;
}


/* ==== Green collapse color ================================== */


.site-collapse-custom-collapse.greenCollapse .site-collapse-custom-panel .ant-collapse-header div {
    color: #00CA84;
    border: 3px solid #00CA84;
}

.site-collapse-custom-collapse.greenCollapse .site-collapse-custom-panel .ant-collapse-header .ant-collapse-header-text {
    color: #00CA84;
}

.site-collapse-custom-collapse.greenCollapse .site-collapse-custom-panel .ant-collapse-header div.ant-collapse-extra {
    color: #00CA84;
    border: 0px solid #333333;
}

.site-collapse-custom-collapse.redCollapse .site-collapse-custom-panel .ant-collapse-header div {
    color: #ff0000;
    border: 3px solid #ff0000;
}

.site-collapse-custom-collapse.redCollapse .site-collapse-custom-panel .ant-collapse-header .ant-collapse-header-text {
    color: #ff0000;
}

.site-collapse-custom-collapse.redCollapse .site-collapse-custom-panel .ant-collapse-header div.ant-collapse-extra {
    color: #ff0000;
    border: 0px solid #333333;
}


/* ======= =========================================== */

.preferred-payment-methods-box {
    background: #FAFAFA;
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 6px;
}

.credit-debit-card-box {
    background: #FAFAFA;
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 6px;
    border: 1px solid #E6E6E6;
}


/* ======================================= */