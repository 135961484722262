/* .mainDiv {
    padding-left: 20%;
    padding-right: 20%;
} */

.mainDiv {
    padding-left: 20%;
    padding-right: 20%;
    width: 100%;
    align-self: center;
}

.topText {
    font-size: 32px;
    line-height: 38.2px;
    margin-top: 120px;
}

.middleText {
    font-size: 20px;
    line-height: 24px;
    margin-top: 32px;
}

.cardHeading {
    font-family: 'Gotham Rounded';
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    line-height: 21.6px;
    margin-top: 24px;
}

.cardDescription {
    font-size: 16px;
    line-height: 22.4px;
    margin-top: 8px;
}

.nextButton_SUABfooter {
    background-color: #00CA84;
    border: 1px black solid;
    margin-top: 78px;
    height: 44px;
    border-radius: 4px;
    margin-left: 22px;
    margin-bottom: 187px;
}

.cardBox {
    margin-top: 80px;
}