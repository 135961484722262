.bg-img {
    position: relative;
}

.image_gallery {
    height: 260px;
    overflow: hidden;
}

.overlay {
    position: absolute;
    top: 0;
    background-image: linear-gradient(rgb(0, 0, 0, 0.5), rgb(0, 0, 0, 0.5));
    height: 92%;
    width: 95%;
    margin-top: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: larger;
    font-weight: bold;
    cursor: pointer
}

.image_Count {
    color: white;
    opacity: 1;
    font-size: 30px;
    margin-top: 20px;
}

.overlay-hide {
    display: none;
}

#rootDiv {
    margin-top: 73px;
    display: flex;
    flex-wrap: wrap;
}

#blackbox {
    background: #333333;
}

#sitelogosbox {
    display: flex;
    padding: 0px;
    width: 88px;
    height: 24px;
    left: 222px;
    top: 128px;
}

#googlelogo {
    position: static;
    width: 24px;
    height: 24px;
    left: 32px;
    top: 0px;
    margin-left: 8px;
}

#fblogo {
    position: static;
    width: 24px;
    height: 24px;
    left: 0px;
    top: 0px;
}

#twitterlogo {
    position: static;
    width: 24px;
    height: 24px;
    left: 64px;
    top: 0px;
    margin-left: 8px;
}

.detailsSocial-linksBox svg {
    color: black;
    font-size: 16px;
}

.detailsSocial-linksBox .MuiAvatar-colorDefault {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}


/* #name_address {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 70px;
    left: 220px;
    top: 174px;
} */

#shop-name {
    /* font-family: 'Gotham'; */
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 100%;
    color: #FFFFFF;
    margin: 10px 0px;
}

#address {
    top: 2px;
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 100%;
    /* identical to box height, or 16px */
    color: #FFFFFF;
}

#status_time_delivery {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0px;
    /* height: 20px; */
    margin-top: 10px;
}

#status {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    /* identical to box height, or 16px */
    color: #F2994A;
}


/* 
#timing {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #FFFFFF;
} */

#timing svg {
    color: #666666;
    font-size: 18px;
    margin-top: -3px;
}

#delivery {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    /* line-height: 100%; */
    color: #FF5858;
}

#messagestore {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    height: 40px;
    left: 220px;
    top: 312px;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
}

.textinsidebox {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    color: #FFFFFF;
}

@media(max-width: 767px) {
    .textinsidebox {
        font-size: 14px;
    }

    .overlay {
        width: 64%;
    }
}

#addreview {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    height: 40px;
    left: 382px;
    top: 312px;
    /* margin-left: 1%; */
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
}

#optionboxes {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
}

#favourite {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    height: 40px;
    top: 312px;
    /* margin-left: 1%; */
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
}


/* #reviews {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 100%;
    text-decoration-line: underline;
    color: #FFFFFF;
} */

#deliverymesssage {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    /* or 21px */
    margin-top: 24px;
    margin-bottom: 24px;
    color: #FF5858;
}

#offerstext {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 100%;
    /* identical to box height, or 16px */
    margin-top: 32px;
    color: #333333;
}

#offerboxes {
    margin-top: 10px;
}

.discountbox {
    /* background: #00AB8D;
    border: 1px solid #333333;
    box-sizing: border-box;
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 16px;
    padding: 10px; */
    background: #00AB8D;
    border: 1px solid #333333;
    box-sizing: border-box;
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 16px;
    margin-bottom: 16px;
    padding: 10px;
    min-width: 160px;
}

.percent {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 100%;
    /* identical to box height, or 16px */
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.code {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

#bottomhalf {
    width: 100%;
}

#restaruantnavbar {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 60px;
}

#selected {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 100%;
    /* identical to box height, or 16px */
    display: flex;
    align-items: center;
    color: #00CA84;
    cursor: pointer;
}

#notselected {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 100%;
    /* identical to box height, or 16px */
    color: #666666;
    cursor: pointer;
}

#ptcbutton {
    background: #00CA84;
    border: 1px solid #383A48;
    box-sizing: border-box;
    border-radius: 4px;
    /* Inside Auto Layout */
    height: 55px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 24px;
}

#buttontext {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 100%;
    /* identical to box height, or 16px */
    text-align: center;
    color: #FFFFFF;
}

#solidline {
    /* border: 1px solid #E6E6E6; */
    border-bottom: 1px solid #E6E6E6;
}

.about-shopContentBox {
    padding: 20px 15px 20px;
    background-color: #ffffff;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}


/* 
#headingabout {
    color: #000000;
    font-size: 24px;
    margin-bottom: 16px;
    font-family: 'Gotham Rounded';
    font-weight: 500;
}

#subheading {
    color: #333333;
    font-family: 'Gotham';
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 16px;
}

#firstline {
    font-family: 'Gotham Rounded';
    font-weight: 400;
    font-size: 16px;
    color: #333333;
} */
.bg-img-div {
    position: relative;
}

m .bg-img {
    position: absolute;

}

.aboutTopImage {
    /* height: 453px; */
    margin-top: 39px;
}

.carouselImg {
    max-height: 453px;
    width: auto;
}

.carouselbackgroundcolor {
    background-color: #b7bebb;
}

#imagecaption {
    font-family: 'Gotham';
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */
    display: flex;
    align-items: center;
    font-style: italic;
    color: #999999;
    margin-top: 17px;
}

#wholereview {
    font-family: 'Gotham';
    font-size: 16px;
    line-height: 130%;
    /* or 21px */
    display: flex;
    align-items: center;
    margin-top: 40px;
    color: #333333;
}

#solidline2 {
    border: 1px solid #E6E6E6;
    margin-top: 32px;
    margin-bottom: 32px;
}

.about-imagesBox {
    height: 120px;
    max-width: 150px;
    background: #F2F2F2;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

#reviewsheading {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    color: #000000;
    font-weight: 500;
}

#shopimage {
    margin-left: 0px;
    height: 240px;
    width: 304.13px;
}

@media (min-width: 320px) and (max-width: 480px) {
    #offerstext {
        font-family: 'Gotham Rounded';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 100%;
        /* identical to box height, or 16px */
        margin-top: 32px;
        color: #333333;
        margin-left: 10px;
    }

    #sitelogosbox {
        display: flex;
        padding: 0px;
        width: 88px;
        height: 24px;
        /* margin-left: -40px; */
        top: 128px;
    }

    /* #name_address {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 70px;
        margin-left: -40px;
        top: 174px;
    } */
    #status_time_delivery {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 0px;
        /* height: 20px; */
        /* margin-left: -40px; */
        /* margin-top: 10px; */
    }

    #messagestore {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 16px;
        width: 148px;
        height: 40px;
        /* margin-left: -40px; */
        top: 312px;
        border: 1px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: 4px;
    }

    #deliverymesssage {
        font-family: 'Gotham Rounded';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 130%;
        margin-top: 24px;
        margin-bottom: 24px;
        color: #FF5858;
    }

    #shopimage {
        margin-left: 0px;
        height: 240px;
        width: 304.13px;
    }

    #bottomhalf {
        margin-left: 10px;
    }

    #restaruantnavbar {
        /* width: 100%; */
        margin-top: 56px;
        /* margin-inline: 10px; */
    }


}


/* ====== review css ======================== */

.custom-control.custom-switch {
    padding-left: 3.25rem;
}

.custom-control.custom-switch .custom-control-label {
    padding-top: 3px;
}

.custom-control.custom-switch .custom-control-label::before {
    top: 0.15rem;
    left: -3.25rem;
    width: 40px;
    height: 24px;
    pointer-events: all;
    border-radius: 32px;
    background-color: #adb5bd;
}

.custom-control.custom-switch .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #00ca84;
    background-color: #00ca84;
}

.custom-control.custom-switch .custom-control-label::after {
    top: calc(0.15rem + 4px);
    left: calc(-3.25rem + 6px);
    width: calc(1rem - 0px);
    height: calc(1rem - 0px);
    background-color: #ffffff;
    border-radius: 0.5rem;
}

.reviews-contentBox {
    padding: 20px 15px 20px;
    background-color: white;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.reviews-content .media {
    font-family: 'Gotham Rounded';
    margin-top: 20px;
    margin-bottom: 20px;
}

.reviews-content .media .reviews-userProfile {
    width: 80px;
    height: 80px;
    align-items: center;
}

.reviews-content .media .review-title {
    font-size: 16px;
    margin-bottom: 8px;
}

.reviews-content .media .review-subtitle {
    font-size: 18px;
}

.reviews-content .media .review-info {
    color: #999999;
    font-size: 16px;
    line-height: 19px;
    font-weight: 300;
}

.reviews-content .media img {
    max-width: 80px;
    height: auto;
    margin-bottom: 10px;
}

.reviews-content .product-details img {
    margin: 0px;
}

.reviews-content .media .btn.review-read-more {
    padding: 0px;
    color: #666666;
    line-height: 1;
    border-bottom: 1px solid #666666;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
}

@media (max-width: 576px) {
    .reviews-content .media {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .reviews-content .media .review-title {
        font-size: 14px;
    }

    .reviews-content .media .review-subtitle {
        font-size: 16px;
    }

    .reviews-content .media .review-info {
        font-size: 14px;
    }

    .reviews-content .media img {
        max-width: 80px;
        height: auto;
        margin-bottom: 10px;
    }

    .reviews-content .media .btn.review-read-more {
        font-size: 14px;
    }
}

#menuheading {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 100%;
    display: flex;
    align-items: center;
    margin-top: 40px;
    color: #000000;
}

.category-tab-name .nav-pills .nav-link.active,
.category-tab-name .nav-pills .show>.nav-link {
    color: #00ca84;
    background-color: transparent;
    font-family: 'Gotham Rounded';
    font-weight: normal;
}

.category-tab-name .nav-pills .nav-link,
.category-tab-name .nav-pills .nav-link {
    color: #333333;
    background-color: transparent;
    font-family: 'Gotham Rounded';
    font-weight: normal;
    padding-left: 0px;
}

@media (max-width: 991px) {

    .category-tab-name .nav-pills .nav-link,
    .category-tab-name .nav-pills .nav-link {
        padding: 4px 0px;
    }
}


/* .category-tab-name .nav-pills .nav-link:hover {
    color: white;
    background-color: #00ca84;
} */
.text-decoration{
    text-decoration:underline;
}
.product-details-box {
    margin: 40px 0px;
}
.navbar-brand{
    font-size: 16px;
}
.category-tab-name {
    padding: 12px 15px 20px;
    background-color: white;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.category-heading {
    font-family: 'Gotham Rounded';
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
    margin-bottom: 8px;
}

.bestseller-box {
    font-family: 'Gotham Rounded';
    color: #FFFFFF;
    display: flex;
    width: 95px;
    height: 22px;
    padding: 3px 4px 5px;
    background: #F7B600;
    border: 1px solid #333333;
    box-sizing: border-box;
    margin-bottom: 12px;
}

.bestseller-box svg {
    width: 14px;
    height: 14px;
}

.bestseller-box h6 {
    color: #ffffff;
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    margin-bottom: 0px;
    margin-left: 2px;
}

.category-tab-content {
    /* padding: 40px 15px; */
    padding: 20px 15px 20px;
    background-color: white;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.category-tab-content .category-boxes {
    padding: 16px 0px;
    border-bottom: 1px solid #E6E6E6;
}

.category-tab-content .product-name {
    font-family: 'Gotham Rounded';
    font-size: 18px;
    position: relative;
    margin-bottom: 8px;
    line-height: 100%;
}

.rough-price {
    font-family: 'Gotham Rounded';
    display: inline-block;
    text-decoration: line-through;
    color: #999999;
    margin-right: 10px;
}

 .vegLable {
    content: url(../../../../assets/images/svg-icons/veg.jpeg);
    width: 8%;
    height: fit-content;
}

.veganLabel{
    content: url(../../../../assets/images/svg-icons/vegan.jpeg);
    width: 8%;
    height: fit-content;

}

.btn-add-product {
    font-family: 'Gotham Rounded';
    color: #00C78A;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 90%;
    background: #FFFFFF;
    border: 1px solid #333333;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 12px 20px;
}

.btn-add-product:hover {
    color: #ffffff;
    font-size: 16px;
    background: #00C78A;
    border: 1px solid #04a575;
}


/* .product-time-box {
    margin-top: 10px;
} */

.accessTimeIcon {
    color: #999999;
}

.add-product-btnbox {
    position: relative;
    text-align: center;
}

.customisable {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 100%;
    align-items: center;
    color: #999999;
    margin-top: 4px;
    margin-bottom: 0px;
}

.delivery-time-box {
    display: flex !important;
    align-items: center;
    /* margin-top: 10px; */
    margin-bottom: 0px;
    margin-right: 16px;
    display: block;
}


/* .desktop-product-img {
    display: block;
    width: 120px;
    height: 120px;
    margin-right: 24px;
} */

.desktop-product-img {
    display: flex;
    width: 120px;
    height: 120px;
    margin-right: 24px;
    justify-content: center;
}

.mobile-product-img {
    display: none;
}

@media (max-width: 767px) {
    .category-tab-name {
        padding-top: 40px;
        padding-bottom: 20px;
        border-right: 0px solid #E6E6E6;
    }

    /* .category-tab-content {
        padding: 20px 0px;
    } */
    .category-tab-content {
        padding: 20px 15px;
        margin-top: 20px;
    }

    .desktop-product-img {
        display: none;
    }

    .add-product-btnbox {
        position: relative;
        text-align: end;
        margin-right: 20px;
    }

    .mobile-product-img {
        display: block;
        height: 55px;
        position: relative;
    }

    /* .mobile-product-img img {
        width: 120px;
        height: 80px;
        object-fit: cover;
        border-radius: 4px;
        position: absolute;
        right: 0;
    } */
    .mobile-product-img img {
        width: auto;
        height: 50px;
        object-fit: cover;
        border-radius: 4px;
        position: absolute;
        right: 20px;
    }

    .delivery-time-box {
        display: none !important;
    }

    /* .add-product-btnbox {
        position: absolute;
        top: 70px;
        left: 70px;
    } */
}

.review-ratingBox {
    position: static;
    width: 63px;
    /* height: 32px; */
    padding: 3px 4px;
    left: 0px;
    top: 68px;
    background: #00CA84;
    border: 1px solid #383A48;
    box-sizing: border-box;
    box-shadow: 1px 2px 4px rgba(102, 102, 102, 0.25);
}

#star {
    width: 16.63px;
    height: 15.84px;
    color: white;
}

.review-rating {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    color: #FFFFFF;
}

.review-rating svg {
    font-size: 20px;
}

.review-rating p {
    padding-top: 3px;
}


/* ===== Review Modal Css ===================== */

.paraModal-box {
    position: relative;
    padding: 32px;
    border-radius: 24px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    -ms-border-radius: 24px;
    -o-border-radius: 24px;
}

.paraModal-box .modal-title {
    font-size: 24px;
    color: #333333;
    font-weight: 500;
}

.paraModal-box .close-btn-box {
    cursor: pointer;
    position: absolute;
    width: 32px;
    height: 32px;
    top: 32px;
    right: 32px;
    background: rgba(51, 51, 51, 0.1);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.paraModal-box svg.btn-close {
    color: #333333;
    margin-left: 6px;
    margin-top: 3px;
    font-size: 20px;
}

.review-uploadImg-box {
    height: 120px;
    border-radius: 8px;
    background: #FAFAFA;
    border: 1px dashed #E6E6E6;
}


/* ============================ */

.add-review-box .form-control {
    background: #FFFFFF;
    border: 1px solid #333333;
    box-sizing: border-box;
    border-radius: 4px;
}

.btn-doLike {
    color: #333333;
    font-weight: 400;
    font-size: 16px;
    line-height: 90%;
    background: #ffffff;
    border: 1px solid #333333;
    box-sizing: border-box;
    padding: 12px 20px;
    border-radius: 24px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    -ms-border-radius: 24px;
    -o-border-radius: 24px;
}


/* .btn-doLike:hover {
    color: #ffffff;
    font-size: 16px;
    background: #00C78A;
    border: 1px solid #04a575;
} */

.btn-liked {
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    line-height: 90%;
    background: #00C78A;
    border: 1px solid #333333;
    box-sizing: border-box;
    padding: 12px 20px;
    border-radius: 24px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    -ms-border-radius: 24px;
    -o-border-radius: 24px;
    border: 1px solid #04a575;
}

.btn-liked:hover {
    /* color: #333333;
    font-size: 16px;
    background: #ffffff;
    border: 1px solid #333333; */
}


/* ======================================= */

.flash-sale-Box {
    background: rgba(0, 171, 141, 0.1);
    border-radius: 16px;
    margin: 24px 0px;
    padding: 24px 24px;
    position: relative;
}

.flash-sale-Box .vertical-line {
    border-left: 5px solid #00ca84;
    height: 50px;
    position: absolute;
    left: 2%;
    border-radius: 14px;
}


/* .moreTextBox {
    position: absolute;
    top: 48%;
    left: 18%;
    color: #FFFFFF;
} */

.moreTextBox {
    position: absolute;
    top: 0%;
    left: 0%;
    height: 100%;
    width: 100%;
    border-radius: 4px;
    padding-top: 50px;
    padding-left: 25px;
    color: #FFFFFF;
    background-color: rgb(8 8 8 / 18%);
}


/* =================================== */


/* .proceed-to-checkoutBoxNew{

} */

.proceed-to-checkoutBox {
    background-color: #00AB8D;
    position: fixed;
    bottom: 8%;
    /* width: 70%; */
    /* left: 67%; */
    margin: auto;
    z-index: 100;
}

.card.proceed-to-checkoutBox .card-body {
    /* padding: 10px 10px; */
    padding: 3px 6px;
    color: #ffffff;
}

.card.proceed-to-checkoutBox .card-body .total-heading {
    font-size: 14px;
    line-height: 1.2;
}

.card.proceed-to-checkoutBox .card-body .total-price {
    font-size: 18px;
    line-height: 1.2;
    font-weight: 500;
}

.card.proceed-to-checkoutBox .card-body .proceed-to-checkoutLink {
    font-size: 16px;
    color: white;
}
.No_Pic{
    text-align: center;
    font-size: 20px;
margin: 0 auto;
    margin-top: 40%;

}
@media (max-width: 767px) {
.card.proceed-to-checkoutBox .card-body .proceed-to-checkoutLink {
    font-size: 12px;
    color: white;
}
.card.proceed-to-checkoutBox .card-body .total-price{
    font-size: 14px;
}
}