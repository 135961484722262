
.top-page-margin {
    font-family: "Varela Round";
    font-weight: 100;
    margin-top: 160px;
}


.content-to-hide img {
    height: 350px;
    max-width: 100%;
    /* margin-left: 82px; */
}

.content-iconsflotting {
    width: 100%;
    text-align: center;
}

.content-iconsflotting img.homebannerimage {
    margin-top: 12em;
    height: 262px;
    max-width: 100%;
    margin-left: 9rem;
}

@media (max-width: 991px) {
    .top-page-margin {
        margin-top: 120px;
    }

    .top-page-margin-2 {
        padding-top: 120px;
    }
}

@media (max-width: 1140px) {
    .content-to-hide {
        display: block;
    }

    .content-iconsflotting img.homebannerimage {
        margin-left: 0em;
    }

    .content-to-hide img {
        margin-top: -60px;
        height: 387px;
        max-width: 100%;
        margin-left: 23px;
    }
}

.featureCarousal {
    border: 3px solid black;
    border-radius: 16px;
    margin-top: 88px;
    margin-right: 7%;
    margin-left: 7%;
    margin-bottom: 1%;
    background-color: #ffffff;
    /* height: 250px; */
    position: relative;
    align-content: center;
    justify-content: center;
}

.featureCarousal-item-box {
    padding: 30px 10px;
    text-align: center;
}

.featureCarousal-item-box h6 {
    font-size: 16px;
    line-height: 16px;
    font-family: "Gotham Rounded";
    margin-top: 16px;
}

.featureCarousal-item-box p {
    font-size: 14px;
    line-height: 16px;
}

.featureCarousal-item-box a {
    color: #333333;
    font-size: 16px;
    margin-top: 8px;
}

.circle2 {
    width: 339px;
    height: 339px;
    background-color: #f3f5e6;
    border-radius: 100%;
    position: absolute;
    margin-left: 331px;
    margin-top: 30px;
}

.circle1 {
    width: 385px;
    height: 389px;
    background-color: #f3f5e6;
    border-radius: 100%;
    position: "";
    margin-left: 68%;
    margin-top: -227px;
}

.ellipseHome1 {
    top: 0;
    right: 0;
    position: absolute;
}

.ellipseHome2 {
    top: 665px;
    left: 450px;
    position: absolute;
}

.rating-box {
    width: 63px;
    height: 32px;
    background-color: #00ca84;
    font-size: 16px;
    font-weight: bold;
    color: white;
    display: flex;
    padding: 3px 4px;
}

.discount-offBox {
    margin-left: 20px;
    margin-top: 5px;
    font-size: 16px;
    line-height: 16px;
    color: #ff5858;
    font-weight: bold;
}

/* ====== flashSale-section  ================ */

/* .flashSale_sectionContent .container{
    max-width: 1306px;
} */

.flashSale-section {
    /* padding: 60px 0px; */
    background-color: #fafafa;
    padding: 0px 7%;
}

.flashSale-section .sale-end-timeBox {
    background-color: #ff5858;
    border-radius: 4px;
    color: white;
    padding: 6px 10px;
    margin-bottom: 16px;
}

@media (max-width: 767px) {
    .flashSale-section .sale-end-timeBox {
        margin-bottom: 40px;
    }
}

.flashSale-section .flashSale-heading {
    font-size: 24px;
    line-height: 24px;
    color: #333333;
    /* font-weight: 400; */
    font-family: "Gotham Rounded";
    margin-bottom: 0px;
}

.trending-section .trending-card {
    border: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.flashSale-section .flashSale-card {
    /* margin-bottom: 30px; */
    /* border: 1px solid #000000; */
    background-color: transparent;
}

.flashSale-card {
    transition-duration: 0.8s;
}

.flashSale-card:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    z-index: 0;
}

.flashSale-card .discription {
    display: -webkit-box;
    max-width: 100%;
    min-height: 44px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #333 !important;
    font-size: 14px;
    padding-top: 0px;
    margin: 0px;
}

.flashSale-card .dddiscription {
    display: -webkit-box;
    /* max-width: 100%; */
    min-height: 48px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #333 !important;
    font-size: 21px;
    padding-top: 0px;
    margin: 0px;
}

.flashSale-card .location-map p {
    margin: 0px !important;
    font-size: 14px;
    display: -webkit-box !important;
    font-size: 13px;
    color: #333 !important;
    font-size: 14px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.flashSale-card .location-map p .shop-locationIcon {
    font-size: 19px;
    color: #333333 !important;
    padding: 0px;
    margin: 0px;
}

.flashSale-card .disTextContaine {
    display: -webkit-box;
    max-width: 100%;
    /* min-height: 26px; */
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: red !important;
    font-weight: 400;
    font-size: 13px;
    border: 0.3px solid red;
    padding: 4px;
    margin: 0px 10px;
    max-width: 100%;
}

.flashSale-card .ratignRangecontent {
    height: 30px;
    justify-content: space-between;
    align-items: center;
}

.flashSale-card img.flashSale-img {
    align-self: center;
    border-radius: 0px;
    height: 160px;
    width: 100%;
    /* margin-bottom: 16px; */
    object-fit: cover;
}

.flashSale-card .card-text {
    color: #666666;
}

.flashSale-card .rating-box {
    width: 63px;
    height: 32px;
    background-color: #00ca84;
    font-size: 16px;
    font-weight: bold;
    color: white;
    display: flex;
    padding: 3px 4px;
}

.flashSale-card h4 {
    font-family: "Gotham Rounded";
    font-size: 24px;
    font-weight: 500;
    color: #333 !important;
}

.flashSale-card .discount-offBox {
    margin-left: 0px;
    margin-top: 0px;
    font-weight: bold;
    float: right;
    transition: all 0.2s ease-in-out;
    font-size: 19px;
    line-height: 18px;
    color: #ff5858;
}

.flashSale-card .discount-offBox img {
    width: 25px;
}

@media (max-width: 576px) {
    .navbar-bg {
        background-color: #f8f9fa !important;
        box-shadow: 0px 2px 4px rgb(102 102 102 / 15%) !important;
    }

    .navbar-toggler {
        color: rgba(0, 0, 0, 0.5) !important;
        border-color: rgba(0, 0, 0, 0.1) !important;
    }

    .navbar-bg .navbar-toggler-icon {
        background-image: url(../../../../assets/images/home/navbarmanu-icon.svg);
    }

    .top-page-margin-2 {
        padding-top: 105px !important;
    }

    .flashSale-section .flashSale-heading {
        font-size: 24px;
    }

    .flashSale-card img.flashSale-img {
        height: 160px;
    }

    .flashSale-card {
        margin-bottom: 30px;
    }

    .flashSale-card .card-title {
        font-size: 14px;
    }

    .flashSale-card .card-text {
        color: #666666;
        font-size: 14px;
    }

    .flashSale-card .rating-box {
        width: 60px;
        font-size: 16px;
        padding: 4px 3px;
    }

    .flashSale-card .rating-box svg {
        font-size: 22px;
    }

    .flashSale-card .discount-offBox {
        margin-left: 10px;
        margin-top: 7px;
        font-size: 14px;
    }

    .flashSale-card .discount-offBox img {
        width: 20px;
    }

    .home_seewhatsupinpara {
        font-size: 26px;
        line-height: 100%;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 0px 13px 0px;
    }
}

/* ====== lashSale-section  ================ */

.trending-section {
    padding: 40px 0px;
    margin-right: 8%;
    margin-left: 8%;
}

.trending-section .sale-end-timeBox {
    background-color: #ff5858;
    border-radius: 4px;
    color: white;
    padding: 6px 10px;
}

.trending-section .trending-heading {
    font-size: 24px;
    line-height: 24px;
    color: #333333;
    font-weight: 500;
    font-family: "Gotham Rounded";
    margin-bottom: 24px;
}

.trending-section .trending-card {
    margin-bottom: 30px;
    border: 0px solid #fafafa;
    background-color: transparent;
}

.trending-card img.trending-img {
    align-self: center;
    height: 200px;
    width: 100%;
    /* margin-bottom: 16px; */
    object-fit: cover;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.trending-card .card-text {
    color: #666666;
}

.trending-card .rating-box {
    width: 63px;
    height: 32px;
    background-color: #00ca84;
    font-size: 16px;
    font-weight: bold;
    color: white;
    display: flex;
    padding: 3px 4px;
}

.trending-card .discount-offBox {
    margin-left: 20px;
    margin-top: 5px;
    font-size: 16px;
    line-height: 16px;
    color: #ff5858;
    font-weight: bold;
}

@media (max-width: 576px) {
    .trending-section .trending-heading {
        font-size: 24px;
    }

    .trending-card img.trending-img {
        height: 160px;
    }

    .trending-card {
        margin-bottom: 30px;
    }

    .trending-card .card-title {
        font-size: 14px;
    }

    .trending-card .card-text {
        color: #666666;
        font-size: 14px;
    }

    .trending-card .rating-box {
        width: 60px;
        font-size: 16px;
        padding: 4px 3px;
    }

    .trending-card .rating-box svg {
        font-size: 22px;
    }

    .trending-card .discount-offBox {
        margin-left: 10px;
        margin-top: 7px;
        font-size: 14px;
    }

    .trending-card .discount-offBox img {
        width: 20px;
    }
}

/* ====== toprated-section  ================ */

.ClockCircleOutlined {
    font: 18px;
}

.toprated-section {
    padding: 40px 0px;
    margin: 0 7%;
}

.toprated-section .sale-end-timeBox {
    background-color: #ff5858;
    border-radius: 4px;
    color: white;
    padding: 6px 10px;
}

.toprated-section .toprated-heading {
    font-size: 24px;
    line-height: 24px;
    color: #333333;
    font-weight: 500;
    font-family: "Gotham Rounded";
    margin-bottom: 24px;
}

.toprated-section .toprated-card {
    margin-bottom: 30px;
    border: 0px solid #fafafa;
    background-color: transparent;
}

.toprated-card img.toprated-img {
    align-self: center;
    height: 200px;
    width: 100%;
    margin-bottom: 16px;
    object-fit: cover;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
}

.toprated-card .card-text {
    color: #666666;
}

.toprated-card .rating-box {
    width: 63px;
    height: 32px;
    background-color: #00ca84;
    font-size: 16px;
    font-weight: bold;
    color: white;
    display: flex;
    padding: 3px 4px;
}

.toprated-card .discount-offBox {
    margin-left: 20px;
    margin-top: 5px;
    font-size: 16px;
    line-height: 16px;
    color: #ff5858;
    font-weight: bold;
}

@media (max-width: 576px) {
    .toprated-section .toprated-heading {
        font-size: 24px;
    }

    .toprated-card img.toprated-img {
        height: 160px;
    }

    .toprated-card {
        margin-bottom: 30px;
    }

    .toprated-card .card-title {
        font-size: 14px;
    }

    .toprated-card .card-text {
        color: #666666;
        font-size: 14px;
    }

    .toprated-card .rating-box {
        width: 60px;
        font-size: 16px;
        padding: 4px 3px;
    }

    .toprated-card .rating-box svg {
        font-size: 22px;
    }

    .toprated-card .discount-offBox {
        margin-left: 10px;
        margin-top: 7px;
        font-size: 14px;
    }

    .toprated-card .discount-offBox img {
        width: 20px;
    }
}

/* ===========  gifting-section ============== */

.gifting-heading {
    font-size: 24px;
    line-height: 24px;
    color: #333333;
    font-family: "Gotham Rounded";
    margin-bottom: 24px;
}

.gifting-section {
    padding: 90px 0px;
    background-color: #f2f2f2;
}

.gifting-box {
    color: #444444;
    border: 2px solid #444444;
    border-radius: 32px;
    /* width: 568px; */
    padding: 40px 40px;
}

.gifting-box img {
    width: 82px;
    height: 82px;
    margin-bottom: 40px;
}

.gifting-info {
    font-size: 16px;
    line-height: 120%;
}

/*  
.sticky {
    position: fixed;
    top: -26px;
    left: 17%;
    z-index: 1040;
    
  } */

/* .sticky + .content {
    padding-top: 60px;
  } */

.headersearchpostion-none {
    display: none;
}

.headersearchpostion-block {
    display: block;
}

/* ====================================== */
.wraphome {
    background: url(../../../../assets/images/home/home_banner3.jpg);
    background-size: cover;
    background-attachment: fixed;
    margin: 0;
    padding: 0;
}

.top-page-margin-2 {
    font-family: "Varela Round";
    font-weight: 100;
    padding-top: 8em;
    background: #e6f3fb;
}

.onlybgcolor {
    position: relative;
    background: #b5e1fa;
    height: 77px;
    margin-top: -40px;
}

.content-iconsflotting {
    z-index: 6;
    margin: 23px 0px 0px;
}

.content-iconsflotting .icons {
    display: inline-block;
    height: 100%;
    max-width: 500px;
    width: 100%;
    position: absolute;
    vertical-align: top;
    align-items: center;
    /* float: right; */
    top: 57px;
    right: 10px;
}

.content-iconsflotting .icon-container {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    /* -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: floating;
    animation-name: floating;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; */
    background-color: #fff;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    box-shadow: 0 5px 40px 0 rgba(23, 28, 33, 0.13), 0 20px 13px 0 rgba(23, 28, 33, 0.05);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    opacity: 1;
    /* -webkit-transition: margin 1s cubic-bezier(0.2, 0.6, 0.3, 1), opacity 2s ease;
    transition: margin 1s cubic-bezier(0.2, 0.6, 0.3, 1), opacity 2s ease; */
    /* animation-duration: 4s; */
    height: 144px;
    margin-left: 76px;
    margin-top: -25px;
    position: absolute;
    width: 144px;
}

/* @keyframes floating {
    0% {
        -webkit-transform: translateY(8px) rotate3d(0, 0, 1, 3deg);
        transform: translateY(8px) rotate3d(0, 0, 1, 3deg);
    }

    100% {
        -webkit-transform: translateY(-8px) rotate3d(0, 0, 1, 0deg);
        transform: translateY(-8px) rotate3d(0, 0, 1, 0deg);
    }
} */

.content-iconsflotting .icon {
    width: 100px;
    height: 100px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.content-iconsflotting .google .icon {
    background-image: url(../../../../assets/images/icons/customer-service.png);
}

.content-iconsflotting .google {
    /* -webkit-animation-duration: 3s;
    animation-duration: 3s; */
    height: 75px;
    margin-left: 0em;
    margin-top: 2em;
    position: absolute;
    width: 75px;
}

.content-iconsflotting .microsoft .icon {
    background-image: url(../../../../assets/images/icons/fast-delivery.png);
}

.content-iconsflotting .microsoft {
    /* -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s; */
    /* -webkit-animation-duration: 4s;
    animation-duration: 4s; */
    height: 75px;
    margin-left: 383px;
    margin-top: 37px;
    position: absolute;
    /* -webkit-transition-delay: 0.7s;
    transition-delay: 0.7s; */
    width: 75px;
}

.content-iconsflotting .bitbucket .icon {
    background-image: url(../../../../assets/images/icons/delivery-man.png);
}

.content-iconsflotting .bitbucket {
    /* -webkit-animation-delay: -2.2s;
    animation-delay: -2.2s;
    -webkit-animation-duration: 4s;
    animation-duration: 4s; */
    height: 61px;
    margin-left: 278px;
    margin-top: -42px;
    position: absolute;
    /* -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s; */
    width: 61px;
}

.content-iconsflotting .digitalocean .icon {
    background-image: url(../../../../assets/images/icons/dairy-products.png);
}

.content-iconsflotting .digitalocean {
    /* -webkit-animation-delay: -2.9s;
    animation-delay: -2.9s;
    -webkit-animation-duration: 4s;
    animation-duration: 4s; */
    height: 75px;
    margin-left: 7em;
    margin-top: -3em;
    position: absolute;
    /* -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s; */
    width: 75px;
}

.content-iconsflotting .ubuntu .icon {
    background-image: url(../../../../assets/images/icons/offer.png);
}

.content-iconsflotting .ubuntu {
    /* -webkit-animation-delay: -1.8s;
    animation-delay: -1.8s;
    -webkit-animation-duration: 4s;
    animation-duration: 4s; */
    height: 75px;
    width: 75px;
    margin-left: 29em;
    margin-top: 10em;

    position: absolute;
    /* -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s; */
}

.content-iconsflotting .nginx .icon {
    background-image: url(../../../../assets/images/icons/security.png);
}

.content-iconsflotting .nginx {
    /* -webkit-animation-delay: -2.9s;
    animation-delay: -2.9s;
    -webkit-animation-duration: 4s;
    animation-duration: 4s; */
    height: 75px;
    width: 75px;
    margin-left: -3em;
    margin-top: 18em;
    position: absolute;
    /* -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s; */
}

.content-iconsflotting .github {
    /* -webkit-animation-delay: -2.2s;
    animation-delay: -2.2s;
    -webkit-animation-duration: 4s;
    animation-duration: 4s; */
    height: 40px;
    margin-left: 251px;
    margin-top: 209px;
    position: absolute;
    display: none;
    /* -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s; */
    width: 40px;
}

.content-iconsflotting .facebook .icon {
    background-image: url(../../../../assets/images/icons/delivery.png);
}

.content-iconsflotting .facebook {
    /* -webkit-animation-delay: -2.2s;
    animation-delay: -2.2s;
    -webkit-animation-duration: 4s;
    animation-duration: 4s; */
    height: 40px;
    margin-left: 41px;
    margin-top: 242px;
    position: absolute;
    /* -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s; */
    width: 40px;
}

.content-iconsflotting .wordpress .icon {
    background-image: url(../../../../assets/images/icons/search-engine.png);
}

.content-iconsflotting .wordpress {
    /* -webkit-animation-delay: -2.2s;
    animation-delay: -2.2s;
    -webkit-animation-duration: 4s;
    animation-duration: 4s; */
    height: 80px;
    margin-left: -3em;
    margin-top: 9em;
    position: absolute;
    /* -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s; */
    width: 80px;
}

.content-iconsflotting .cloudflare .icon {
    background-image: url(../../../../assets/images/icons/restaurant.png);
}

.content-iconsflotting .cloudflare {
    /* -webkit-animation-delay: -2.2s;
    animation-delay: -2.2s;
    -webkit-animation-duration: 4s;
    animation-duration: 4s; */
    height: 70px;
    width: 70px;
    margin-left: 30em;
    margin-top: 18em;
    position: absolute;
    /* -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s; */
}

.content-iconsflotting .instagram .icon {
    background-image: url(../../../../assets/images/icons/restaurant-1.png);
}

.content-iconsflotting .instagram {
    /* -webkit-animation-delay: -2.2s;
    animation-delay: -2.2s;
    -webkit-animation-duration: 4s;
    animation-duration: 4s; */
    height: 30px;
    margin-left: 207px;
    margin-top: 69px;
    position: absolute;
    /* -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s; */
    width: 30px;
}

.content-iconsflotting .twitter .icon {
    background-image: url(../../../../assets/images/icons/security.png);
}

.content-iconsflotting .twitter {
    /* -webkit-animation-delay: -2.2s;
    animation-delay: -2.2s;
    -webkit-animation-duration: 4s;
    animation-duration: 4s; */
    height: 30px;
    margin-left: 207px;
    margin-top: 25px;
    position: absolute;
    /* -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s; */
    width: 30px;
}

.content-iconsflotting .linkedin .icon {
    background-image: url(../../../../assets/images/icons/search-engine.png);
}

.content-iconsflotting .linkedin {
    /* -webkit-animation-delay: -2.2s;
    animation-delay: -2.2s;
    -webkit-animation-duration: 4s;
    animation-duration: 4s; */
    height: 30px;
    margin-left: 395px;
    margin-top: 250px;
    position: absolute;
    /* -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s; */
    width: 30px;
}

.content-iconsflotting .twitter {
    /* -webkit-animation-delay: -2.2s;
    animation-delay: -2.2s;
    -webkit-animation-duration: 4s;
    animation-duration: 4s; */
    height: 30px;
    margin-left: 245px;
    margin-top: 360px;
    position: absolute;
    /* -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s; */
    width: 30px;
}

.media-body .form-group .media-body .form-group input::-webkit-outer-spin-button,
.media-body .form-group input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.media-body .form-group input[type=number] {
    -moz-appearance: textfield;
}

.person-message-box .bubble,
.my-message-box .bubble {
    border-radius: 8px;
    background: #fff;
    padding: 12px 18px;
    border: 0.5px solid rgba(0, 0, 0, 0.02);
    box-shadow: 0 2px 2px rgb(0 0 0 / 23%);
    max-width: 70%;
    display: inline-flex;
    text-align: left;
    flex-direction: column;
    margin: 10px 0px;
}


/* .big-img-productcontentImage {
    width: 99%;
    height: 280px;
    object-fit: cover;
    margin-bottom: 20px;
}

.big-img-productcontentImage img {
    width: 100%;
    height: inherit;
    object-fit: cover;
    object-position: center;

} */
.imageMg{
z-index: 100;
}


.small-img-productcontentImage img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin-bottom: 15px;
    border-radius: 7px;

}
.price1{
    color: #b12704;
}
.description-last{
    font-size: 14px;
}
.product_select_image{
    height: 115px;
}