/* .root1 {
    margin-top: 140px;
    width: 100%;
} */

.business-setup-header {
    margin-top: 105px;
    width: 100%;
}

.business-setup-header .headerText {
    font-size: 48px;
    line-height: 48px;
    font-weight: 400;
    font-style: normal;
}

.business-setup-header .headerBox {
    width: 501px;
    height: 96px;
}

/* .business-setup-header .headerImage {
    margin-top: 62px;
} */

.buttonbox {
    margin-top: 35px;
}

#buttonheader {
    color: white;
    font-size: 16px;
    height: 44px;
    width: 265px;
    border-radius: 24px;
    background-color: #383A48;
    border: 0px;
    font-weight: 400;
    font-family: 'Gotham rounded';
}

@media (max-width:1140px) {
    .content-to-hide {
        display: none;
    }
}

.box-border {
    padding-top: 4%;
    padding-bottom: 4%;
    border-radius: 16px;
    border: 2px black solid;
    margin-top: 78px;
    background-color: white;
    box-shadow: 1px 3px rgba(102, 102, 102, 0.25);
    display: 'flex';
}

.fraction {
    font-size: 48px;
    line-height: 48px;
    font-weight: 400;
    font-family: 'Gotham rounded';
    color: rgba(0, 174, 139, 1)
}

.description {
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
}