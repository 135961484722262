/* .root_ays {
    margin-top: 80px;
} */
.borderPostcode{
border-color: #ff4d4f !important;
}
.logotext_ays {
    font-family: Varela Round;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    margin-top: 50px;
}

.labeltext_ays {
    font-family: Varela Round;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
}

#labeltext_ays1 {
    font-family: Varela Round;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
}

.boxes_ays {
    margin-top: 0px;
}

#textarea_ays {
    height: 120px;
}

#descibeBusiness {
    height: 170px;
}

#uploadfiles_ays {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 76px;
    background: #FAFAFA;
    border: 1px dashed #B8B8B8;
    border-radius: 8px;
}

#uploadfiles_ays1 {
    padding: 15px;
    background: #FAFAFA;
    border: 1px dashed #B8B8B8;
    border-radius: 8px;
}

#uploadbannerbefore {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 76px;
    background: #FAFAFA;
    border: 1px dashed #B8B8B8;
    border-radius: 8px;
}

#uploadbannerafter {
    background: #FAFAFA;
    border: 1px dashed #B8B8B8;
    border-radius: 8px;
}

#upload_text_ays {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #999999;
}

#underlinegreen {
    color: #00AB8D;
    text-decoration: underline;
}

#underlinegreen1 {
    color: #00AB8D;
    text-decoration: underline;
}

#openhours_ays {
    margin-top: 32px;
}

.days_ays {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    color: #333333;
}

.fromto_ays {
    font-family: Varela Round;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #333333;
}

.box_background {
    background: #FAFAFA;
    border-radius: 4px;
    margin-bottom: 8px;
}

#finishButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 32px;
    position: static;
    width: 118px;
    height: 56px;
    left: 137px;
    top: 0px;
    background: #00CA84;
    border: 1px solid #333333;
    box-sizing: border-box;
    border-radius: 4px;
}

#finishbuttontext {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 100%;
    text-align: center;
    color: #FFFFFF;
}

#bottomoptions {
    margin-top: 80px;
    margin-bottom: 75px;
}

#skipfornow {
    cursor: pointer;
}


/* ================================ */

.root_cpm {
    margin-top: 80px;
}

.step3 {
    font-family: 'Gotham Rounded';
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    font-style: normal;
    color: rgba(0, 171, 141, 1);
}

.cpm_text {
    font-family: "Gotham Rounded";
    font-weight: 400;
    font-size: 32px;
    line-height: 32px;
    font-style: normal;
}

.cpm_bottom_text {
    font-weight: 400;
    font-size: 16px;
    line-height: 19.2px;
    font-style: normal;
}

.cpm_outer_box {
    margin-top: 40px;
}

.cpm_connectbutton {
    background-color: #00CA84;
    border: 1px black solid;
    width: 140px;
    height: 56px;
    margin-top: 24px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.box1_cps_text {
    font-family: 'Varela Round';
    font-weight: bold;
    font-style: normal;
    font-size: 20px;
    line-height: 20px;
    margin-top: 42px;
}

.box1_cps_desc {
    font-family: "Varela Round";
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 19.2px;
}

.box1_cps_learnhow {
    font-family: "Varela Round";
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 19.2px;
    color: rgba(0, 171, 141, 1);
}

.cpm_box1 {
    padding: 40px;
    border: 2px solid rgba(0, 171, 141, 1);
    box-shadow: 0px 0px 0px 4px rgba(0, 171, 141, 0.25);
    border-radius: 8px;
    margin-bottom: 20px;
}

.cpm_box2 {
    padding: 40px;
    border: 1px solid black;
    border-radius: 8px;
    margin-bottom: 20px;
}

.suys_step1_maindiv {
    border-radius: 16px;
    position: relative;
}

@media (max-width: 991.98px) {
    .cpm_box1 {
        padding: 15px;
    }
    .cpm_box2 {
        padding: 15px;
    }
}

.step1_connectwithstripe {
    font-family: 'Gotham Rounded';
    font-style: normal;
    line-height: 18px;
    font-weight: 400;
    font-size: 18px;
    margin-top: 24px;
    margin-bottom: 24px;
}

.step1_greenbox {
    background-color: rgba(0, 202, 132, 0.1);
    margin-top: 32px;
    border-radius: 4px;
    padding: 16px;
}

.connectwithstripe_progress {
    font-family: 'Gotham Rounded';
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
}

.success_continuebutton {
    width: 111px;
    border-radius: 4px;
    padding: 12px, 16px, 12px, 16px;
    background-color: rgba(0, 202, 132, 1);
    color: white;
    border: 1px black solid;
    margin-bottom: 20px;
    height: 48px;
}

.disconnect_text {
    font-family: 'Gotham Rounded';
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 40px;
    margin-top: 42px;
}

.cpm_bottombox {
    margin-top: 130px;
    margin-bottom: 55px;
}

.cpm_nextbutton {
    width: 107px;
    border-radius: 4px;
    padding: 12px, 32px, 12px, 32px;
    background-color: rgba(0, 202, 132, 1);
    color: white;
    border: 1px black solid;
    height: 56px;
}

#unsuccess_button {
    position: static;
    width: 100px;
    height: 48px;
    left: 0px;
    top: 0px;
    background: #999999;
    border: 1px solid #383A48;
    box-sizing: border-box;
    border-radius: 4px;
}

#success_button {
    position: static;
    width: 100px;
    height: 48px;
    left: 0px;
    top: 0px;
    background: #00CA84;
    border: 1px solid #383A48;
    box-sizing: border-box;
    border-radius: 4px;
}


/* ================================ */

.root_delivery {
    margin-top: 80px;
}

.delivery_box1 {
    border: 2px solid #00AB8D;
    box-shadow: 0px 2px 4px rgba(51, 51, 51, 0.2);
    border-radius: 8px;
}

.delivery_box2 {
    border: 1px solid #BDBDBD;
    border-radius: 8px;
}

.delivery_outer_box {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
}

.deliver_yes_no {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    margin-top: 40px;
}

.delivery_desc1 {
    /* font-family: 'Gotham Rounded'; */
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    margin-top: 16px;
}

.delivery_desc2 {
    /* font-family: 'Gotham Rounded'; */
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    margin-top: 16px;
}

.inputbox_label {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    margin-top: 32px;
}

.delivery_images {
    margin-top: 40px;
}

.delivery_nextbutton {
    width: 107px;
    border-radius: 4px;
    padding: 12px, 32px, 12px, 32px;
    background-color: rgba(0, 202, 132, 1);
    color: white;
    border: 1px black solid;
    height: 56px;
}

.delivery_bottombox {
    margin-top: 130px;
    margin-bottom: 55px;
}


/* ================================ */

.heading12 {
    font-weight: 400;
    font-size: 32px;
    font-style: normal;
    margin-top: 81px;
    /* width: 680px; */
}

.description12 {
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    line-height: 25.2px;
    font-family: 'Gotham Rounded';
    max-width: 680px;
    margin: auto;
}


/* .img12 {
    margin-top: 140px;
} */

.box122 {
    height: 680px;
    max-width: 680px;
}

.profile_setupyourshop {
    background-color: #00CA84;
    border: 1px black solid;
    width: 189px;
    margin-top: 50px;
    height: 56px;
    border-radius: 4px;
    margin-bottom: 120px;
}


/* ================================ */