@import "../../../assets/css/variables.css";

.root {
    flex-grow: 1;
}

li {
    list-style: none;
}

.circularBreadCrumb {
    position: relative;
}

.itemCount {
    position: absolute;
    top: 0%;
    left: 0%;
}

.navbar-light {
    box-shadow: 0px 2px 4px rgba(102, 102, 102, 0.15);
}

.navbar-light.shopDetail-navbar {
    box-shadow: none;
    background-color: white;
    border-radius: 8px;
}

.navbar-light .navbar-nav .nav-link {
    color: var(--custom-main-btn-secondary);
    font-size: 16px;
}

.menuButton {
    margin-right: 2px;
}

.title {
    flex-grow: 1;
}

.links {
    margin-left: 10px;
    margin-right: 10px;
}

/* .loginbutton {
    border: 2px solid black;
    border-radius: 5px;
    margin-top: 8px;
} */

.loginbutton .btn-login {
    font-size: 16px;
    color: var(--custom-main-btn-secondary);
    border: 2px solid black;
    border-radius: 5px;
}

/* .signupbutton {
    margin-right: 16px;
    margin-left: 32px;
    border-radius: 5px;
    border: 2px solid black;
    background-color: #00CA84;
    margin-top: 8px;
} */

.signupbutton .btn-signup {
    /* color: #ffffff; */
    color: var(--custom-main-btn-secondary);
    font-size: 16px;
    margin-right: 16px;
    margin-left: 32px;
    border-radius: 5px;
    border: 2px solid black;
    /* background-color: #00ca84; */
    background-color: var(--custom-main-btn-primary);
}

@media (max-width: 1140px) {
    .content-to-hide {
        display: none;
    }
}

.btn-circular-cart {
    margin-left:25px;
    cursor: pointer;
    margin-right: -11px;
}
.badge_number{
    position: absolute;
    /* top: 18%; */
    font-size: 15px;
    background-color: #00ca84;
}
@media (min-width: 1200px) {
    .full {
        display: none;
    }
}

/* ==== Delete ========  */

#modal_tital {
    font-family: Varela Round;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 32px;
}

/* ==== Delete ========  */

.dropdown-toggle:after {
    border: 0;
}

#logoutbutton {
    padding: 0;
    /* width: 44px; */
    height: 44px;
    border: none;
    border-radius: 22px;
    background: none;
}

.user-name-email-box {
    line-height: 1.4;
}

.user-name-email-box .user-name {
    font-size: 14px;
    /* width: 100px; */
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.user-name-email-box .user-email {
    font-size: 12px;
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* ========  shopping-cart ====================== */

.lighter-text {
    color: #abb0be;
}

.shopping-cart-price {
    color: #00ca84;
}

.cartbadge {
    background-color: #00ca84;
    border-radius: 10px;
    color: white;
    display: inline-block;
    font-size: 12px;
    line-height: 1;
    padding: 3px 7px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
}

.shopping-cart {
    width: 340px;
    border-radius: 3px;
    padding: 20px;
    z-index: 999;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

@media (max-width: 991.98px) {
    .shopping-cart {
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 340px;
        border-radius: 3px;
        padding: 20px;
        z-index: 999;
        background: white;
        /* display: none; */
    }

    .mobileviewInput {
        display: none !important;
    }

    .cartbadge  {
        display: none !important;
    }
}
@media(max-width: 768px) { 
    .txt-menu {
       display:none; 
    }
}

@media (max-width: 767.98px) {
    .content-to-hide img.homebannerimage {
        height: 319px;
    }
.btn-circular-cart{
    display: none;
}


    .signupbutton.sign-up-btn {
        display: none !important;
    }

    .loginbutton.sign-in-btn {
        display: none !important;
    }

    .icon-container.linkedin {
        display: none !important;
    }

    .icon-container.nginx {
        display: none !important;
    }

    .icon-container.ubuntu {
        display: none !important;
    }

    .icon-container.microsoft {
        display: none !important;
    }

    .icon-container.bitbucket {
        display: none !important;
    }

    .icon-container.wordpress {
        display: none !important;
    }

    .navHeader-search {
        margin-top: 5px !important;
        width: 100% !important;
    }

    .shopping-cart {
        position: fixed;
        top: 40%;
        left: 50%;
        max-width: 100%;
        border-radius: 3px;
        padding: 20px;
        z-index: 999;
        background: white;
        /* display: none; */
    }

    .content-iconsflotting .icons {
        display: inline-block;
        height: 36%;
        max-width: 310px;
        width: 20% !important;
        position: absolute;
        vertical-align: top;
        align-items: center;
        /* float: right; */
        top: 15px !important;
        /* right: 135px; */
        left: 46px;
    }

    .content-iconsflotting .wordpress {
        margin-left: 17em !important;
        margin-top: 13em !important;
    }

    .content-iconsflotting .twitter,
    .content-iconsflotting .cloudflare {
        display: none !important;
    }

    .content-iconsflotting .nginx {
        margin-left: -3em !important;
        margin-top: 10em !important;
    }
}

.shopping-cart .shopping-cart-header {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 15px;
}

.shopping-cart .shopping-cart-header .shopping-cart-total {
    float: right;
}

.shopping-cart .shopping-cart-items {
    padding-top: 15px;
    padding-left: 0px;
    list-style-type: none;
    border-bottom: 1px solid #e8e8e8;
    width: 100%;
    max-height: 280px;
    overflow-y: auto;
}

.shopping-cart .shopping-cart-items::-webkit-scrollbar-track {
    background-color: #f5f5f5;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.shopping-cart .shopping-cart-items::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
}

.shopping-cart .shopping-cart-items::-webkit-scrollbar-thumb {
    background-color: #00ca84;
}

.shopping-cart .shopping-cart-items li {
    margin-bottom: 15px;
}

.shopping-cart .shopping-cart-items img {
    float: left;
    margin-right: 12px;
    max-width: 50px;
    /* max-height: 50px; */
}

.shopping-cart .shopping-cart-items .item-name {
    display: block;
    /* padding-top: 10px; */
    font-size: 16px;
}

.shopping-cart .shopping-cart-items .item-price {
    color: #333;
    margin-right: 8px;
    font-weight: 500;
}

.shopping-cart .shopping-cart-items .item-quantity {
    color: #abb0be;
}

.shopping-cart .cart-icon {
    color: #848080;
    font-size: 24px;
    margin-right: 7px;
    float: left;
}

.shopping-cart .shopping-cart-header .cartbadge {
    padding: 4px 7px;
    background-color: #00ca84;
    margin-bottom: 2px;
    margin-left: 6px;
}

.cart-show.callusbtn {
    text-decoration: none;
}

.shopping-cart .button {
    background-color: #00ca84;
    color: white;
    text-align: center;
    padding: 12px;
    text-decoration: none;
    display: block;
    border-radius: 3px;
    font-size: 16px;
    margin: 20px 0 15px 0;
    border-top: 1px solid #e8e8e8;
}

.shopping-cart .button:hover {
    background-color: #00ca84;
}

.cart-badge {
    background-color: #00ca84;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    padding: 2px 5px;
    margin-top: -18px;
    margin-left: -18px;
}

.cartmobile-badge {
    font-size: 12px;
    font-weight: 400;
    margin-top: -15px;
    margin-left: -4px;
    padding: 2px 5px;
    background-color: #00ca84;
}

/* =========================== */

.header-search {
    width: 160px;
    box-sizing: border-box;
    border: 1px solid var(--custom-main-btn-secondary);
    border-radius: 30px;
    /* font-size: 16px; */
    background-color: #f8f9fa;
    background-image: url("../../../assets/images/svg-icons/search-iconBlack.svg");
    background-position: 10px 10px;
    background-repeat: no-repeat;
    padding: 6px 20px 6px 36px;
}

.navHeader-search {
    width: 160px;
    box-sizing: border-box;
    border: 0px solid var(--custom-main-btn-secondary);
    border-radius: 30px;
    /* font-size: 16px; */
    /* background-color: #f8f9fa; */
    background-position: 10px 10px;
}

.navHeader-search .ant-select-selector {
    width: 160px;
    box-sizing: border-box;
    border: 1px solid #333333 !important;
    background-color: transparent !important;
    background-image: url("../../../assets/images/svg-icons/search-iconBlack.svg");
    background-position: 10px 10px;
    background-repeat: no-repeat;
    padding: 6px 20px 6px 36px;
    border-radius: 20px !important;
    -webkit-border-radius: 20px !important;
    -moz-border-radius: 20px !important;
    -ms-border-radius: 20px !important;
    -o-border-radius: 20px !important;
}

.navHeader-search .ant-select-selection-placeholder {
    padding-left: 20px !important;
    line-height: 30px !important;
}

.navHeader-search input.ant-select-selection-search-input {
    height: 36px !important;
    padding-left: 20px !important;
}

.header-addtocard-button {
    box-shadow: none !important;
}

.ant-popover-placement-bottom {
    margin: 0px 10px !important;
}

.ant-btn:hover.header-addtocard-button,
.ant-btn.header-addtocard-button:focus {
    color: #ffffff00;
    border-color: #ffffff00;
    background: #ffffff00;
}

.ant-popover-inner-content {
    padding: 0px !important;
}

.ant-popover-inner {
    background-color: unset !important;
    background-clip: unset !important;
    border-radius: 0px !important;
    box-shadow: none !important;
}

.ant-modal.searchmodel {
    /* height: 100vh; */
    top: 12%;
    max-width: calc(100vw - 5px);
    margin: 0px auto;
}


img.search-icon-modal {
    border: 1px solid black;
    padding: 9px;
    border-radius: 50px;
    color: #000;
    width: 39px;
}

.ant-modal.searchmodel .ant-modal-body {
    padding: 24px;
    font-size: 14px;
    line-height: 11.5715;
}

.admin-user-icon img {
    height: auto !important;
    margin: auto !important;
}

@media (max-width: 991.98px) {
    .mblSignUp {
        display: none !important;
    }

}
.ant-btn:hover.header-addtocard-button, .ant-btn.header-addtocard-button:focus{
    color: #000;
}