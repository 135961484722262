@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import 'react-pro-sidebar/dist/css/styles.css';
body {
    margin: 0;
    height: 100vh;
    color: #353535;
    font-family: 'Gotham Rounded' !important;
}

#root {
    height: 100%;
}

.Sidebar-MainContain {
    min-height: 700px;
    /* height: 100%; */
    display: flex;
    position: relative;
}

.Sidebar-MainContain .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #353535;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    display: none;
}

.Sidebar-MainContain .sidebar-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Sidebar-MainContain .sidebar-btn-wrapper .sidebar-btn {
    transition: width 0.3s;
    width: 150px;
    padding: 1px 15px;
    border-radius: 40px;
    background: rgba(255, 255, 255, 0.05);
    color: #adadad;
    text-decoration: none;
    margin: 0 auto;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-overflow: ellipsis;
    overflow: hidden;
}

.Sidebar-MainContain .sidebar-btn-wrapper .sidebar-btn span {
    margin-left: 5px;
    font-size: 13px;
}

.Sidebar-MainContain .sidebar-btn-wrapper .sidebar-btn:hover {
    color: red;
}

.Sidebar-MainContain .collapsed .sidebar-btn {
    display: inline-block;
    line-height: 40px;
    padding: 0;
}

.Sidebar-MainContain main {
    padding: 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.Sidebar-MainContain main header {
    margin-bottom: 40px;
    border-bottom: 1px solid #efefef;
}

.Sidebar-MainContain main header h1 {
    display: flex;
    align-items: center;
    transform: translateX(-20px);
}

.Sidebar-MainContain main footer {
    margin-top: auto;
    color: #888;
    text-align: center;
}

.Sidebar-MainContain main footer a {
    text-decoration: none;
    color: #888;
}

.Sidebar-MainContain main footer a:hover {
    text-decoration: underline;
}

.Sidebar-MainContain .social-bagdes {
    margin-top: 10px;
}

.Sidebar-MainContain .social-bagdes img {
    margin: 5px;
}

.Sidebar-MainContain .block {
    display: flex;
    margin-bottom: 24px;
    font-size: 14px;
    color: #545454;
}

.Sidebar-MainContain .block>span {
    margin-left: 10px;
}

.Sidebar-MainContain.rtl {
    direction: rtl;
    text-align: right;
}

.Sidebar-MainContain.rtl header h1 {
    transform: translateX(20px);
}

.Sidebar-MainContain.rtl .block {
    direction: rtl;
}

.Sidebar-MainContain.rtl .block>span {
    margin-left: 0;
    margin-right: 10px;
}

.Sidebar-MainContain.rtl .sidebar-btn-wrapper .sidebar-btn span {
    margin-left: 0;
    margin-right: 5px;
}

.sidebar-right-content-spacing {
    margin-top: 75px;
}

@media (max-width: 767.98px) {
    .Sidebar-MainContain {
        overflow-x: hidden;
    }
    .Sidebar-MainContain header h1 {
        font-size: 24px;
    }
}

@media (max-width: 991.98px) {
    .Sidebar-MainContain {
        height: 100%;
        display: block;
        position: relative;
    }
    .Sidebar-MainContain .btn-toggle {
        display: flex;
        margin-left: 20px;
    }
    .Sidebar-MainContain.rtl .btn-toggle {
        margin-left: auto;
    }
    .sidebar-right-content-spacing {
        margin-top: 20px;
    }
}

.badge {
    padding: 3px 6px;
    font-size: 9px;
    letter-spacing: 1px;
    border-radius: 15px;
}

.badge.red {
    color: #fff;
    background: #d63030;
}

.badge.gray {
    color: #fff;
    background: #777;
}

.badge.yellow {
    color: #000;
    background: #ffd331;
}


/* ==== New ====== */

.pro-sidebar-header .sidebar-topHeader {
    padding: 24px 24px 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.pro-sidebar-header .sidebar-topHeader h6 {
    color: white;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.pro-sidebar-header .sidebar-topHeader h6.admin-name {
    color: white;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.pro-sidebar-header .sidebar-topHeader p {
    color: #00CA84;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 0px;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-header {
    border-bottom: 1px solid rgba(173, 173, 173, 0.2);
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-icon-wrapper .pro-icon svg {
    width: 20px;
    height: 20px;
}

.pro-sidebar .pro-menu {
    padding-top: 10px;
    padding-bottom: 30px;
}

.pro-sidebar .pro-menu .pro-menu-item.active {
    color: #00c78a;
}