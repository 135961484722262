@import "../../../../assets/css/variables.css";

@media (max-width: 1200px) {
    .content-to-hide {
        display: none;
    }
}

@media screen and (max-width: 320px) {
    .footerDesc {
        font: "8px";
    }
}

@media screen and (max-width: 1204px) {
    .footer {
        font: "18px";
    }
}

.online-business-box {
    border-radius: 16px;
    border: 3px solid black;
    padding: 20px;
}

.online-business-box .online-business-innerbox {
    padding: 20px;
}

.online-business-box h2 {
    font-size: 36px;
    line-height: 36px;
    font-weight: bold;
}

.online-business-box p {
    margin-top: 16px;
    font-size: 16px;
    line-height: 19.2px;
}

.online-business-box .btn-online-business {
    padding: 6px 15px;
    border-radius: 4px;
    /* background-color: rgb(0, 202, 132); */
    background-color: var(--custom-main-btn-primary);
    border: 1px solid #333333;
    /* color: white; */
    color: #333333;
}

.online-business-box .btn-online-business:hover {
    color: #ffffff;
    /* background: #00aa6f; */
    background: var(--custom-main-btn-secondary);
    border: 1px solid #333333;
}

.online-business-box .online-business-img {
    max-width: 100%;
    height: auto;
    margin-top: -80px;
}

.online-business-box .online-business-img {
    max-width: 100%;
    height: auto;
    margin-top: 0px;
}

@media screen and (min-width: 1200px) {
    .online-business-box .online-business-img {
        max-width: 100%;
        height: auto;
        margin-top: -80px;
    }
}
