/* .maindiv {
    margin-top: 60px;
    width: 100%;
    align-self: center;
} */

.testimony {
    margin-top: 60px;
    width: 100%;
    align-self: center;
}

.testimony .quote {
    font-size: 32px;
    line-height: 38.4px;
    color: white;
    margin-top: 24px;
}

.testimony .name {
    font-size: 18px;
    line-height: 21.6px;
    color: white;
    margin-top: 24px;
}

.testimony .readMore {
    font-weight: 400px;
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    color: white;
    margin-top: 48px;
}

.testimonyImage {
    margin-bottom: 60px;
    overflow-x: hidden;
}

.testimonyBox {
    max-width: 550px;
}