@import "../../../../assets/css/variables.css";

#rootDiv {
    margin-top: 73px;
    display: flex;
    flex-wrap: wrap;
}
.border_red{
    border: 2px solid green;
}

.spinnerBackground{
    background-image: linear-gradient(rgb(0, 0, 0,0.2),rgb(0, 0, 0,0.2));
}
/* ================================ */

.nav-tabs.shopTabs {
    border-bottom: 0px solid #dee2e6;
}

.nav-tabs.shopTabs .nav-link.active,
.nav-tabs.shopTabs .nav-item.show .nav-link {
    color: var(--custom-main-btn-secondary);
    background-color: #fff;
    border-bottom: 4px solid var(--custom-main-btn-primary);
    font-weight: 500;
}

.nav-tabs.shopTabs .nav-link {
    color: #666666;
    margin-bottom: -1px;
    border: 0px solid transparent;
    border-radius: 0px;
    padding: 20px 6px;
    margin: 0px 10px;
    line-height: 1.4;
}


/* .nav-tabs.shopTabs .nav-link {
    color: #666666;
    margin-bottom: -1px;
    border: 0px solid transparent;
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
} */


/* ================================ */
.ant-drawer-wrapper-body{
    display: block !important;
}
.ant-drawer-header-title {
    display: flex;
    flex-direction: row-reverse ;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.ant-drawer-title{
    font-size: 25px !important;
}
/* .shops-box {
    padding: 60px 0px;
} */

.card.shopsBox-card {
    background-color: transparent;
    /* margin: 24px 0px; */
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}
h5.shop-shopname{
    font-family: "Gotham Rounded";
    /* font-size: 24px; */
    font-weight: 500;
    color: #333 !important;
    margin-top: 15px;
    display: -webkit-box;
}

.flashSale-card .ddiscription {
    display: -webkit-box;
    /* max-width: 100%; */
    min-height: 48px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #333 !important;
    /* font-size: 14px; */
    padding-top: 0px;
    margin: 0px;
}

.shop-image {
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    align-self: center;
    border-radius: 0px;
    height: 160px;
    width: 100%;
    /* margin-bottom: 16px; */
    object-fit: cover;
}

.shop-location {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 100%;
    color: #666666;
    line-height: 1.2;
}

.shop-locationIcon {
    color: #666666;
    width: 20px;
    height: 20px;
    margin-right: 6px;
}

.shop-ratingBox {
    display: flex;
    align-items: center;
    /* padding: 0px 0px; */
    width: 61px;
    height: 25px;
    color: white;
    position: static;
    /* width: 63px; */
    padding: 3px 4px;
    left: 0px;
    top: 68px;
    background: #00CA84;
    border: 1px solid #383A48;
    box-sizing: border-box;
    box-shadow: 1px 2px 4px rgba(102, 102, 102, 0.25);
}

.shop-rating {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    color: #FFFFFF;
}

.shop-rating svg {
    color: white;
    font-size: 20px;
}

.shop-rating p {
    padding-top: 2px;
    padding-left: 2px;
}

.shop-ratingBox.ratingBox-yellow {
    background: #F7B600
}

.shop-ratingBox.ratingBox-red {
    background: #F1657C;
}