

.topHeader-section {
    padding: 50px 0px 50px;
    background-color: #333333;
    margin-top: 77px;
}
.SelectedDoc{
    color: #00CA84;
}

.topHeader-box .heading {
    color: white;
    line-height: 19px;
    text-align: left;
}

.topHeader-box .sub-heading {
    color: white;
    line-height: 19px;
}


/* ====================== */

.sellerProfile-section {
    background-color: #FAFAFA;
    padding: 32px 0px 40px;
}

.profile-tab-name {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 20px 10px;
    margin-top: 16px
}

.profile-tab-name .btn-logout {
    background: #FFFFFF;
    margin: 8px 16px;
    padding: 0px;
    outline: none;
}

.profile-tab-name .btn-logout:hover {
    color: #00CA84;
    margin: 8px 16px;
    padding: 0px;
}

.profile-tab-name .btn-logout:focus,
.profile-tab-name .btn-logout:active {
    outline: none;
    box-shadow: none;
}

.profile-tab-name .nav a.nav-link {
    position: relative;
    color: #333333;
}

.profile-tab-name .nav a.nav-link.active {
    color: #00CA84;
    background-color: #FFFFFF;
}

.profile-tab-name .nav a.nav-link:hover {
    color: #00CA84;
}

.profile-tab-name .nav a.nav-link.active::after {
    content: url('../../images/sellerProfile/arrow-left.svg');
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    right: 10px;
    top: 11px;
}

.profile-tab-content .profile-info .mybusinesses h4 {
    font-size: 18px;
    margin-bottom: 6px;
}

.profile-tab-content .profile-info .mybusinesses p {
    color: #666666;
    font-size: 16px;
    margin-bottom: 0px;
}

.my-info img {
    width: 80px;
    height: 80px;
}

.upload-btn-wrapper {
    position: absolute;
    top: 45px;
    left: 60px;
    overflow: hidden;
    display: inline-block;
}

.upload-btn-wrapper1 {
    position: absolute;
    right: 0px;
    overflow: hidden;
    display: inline-block;
    bottom: 0px;
}
.upload-btn {
    border: 0px solid gray;
    color: gray;
    background-color: white;
    padding: 0px 0px;
    border-radius: 50%;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
}

.upload-btn .edit-profile-icon {
    width: 28px;
    height: 28px;
    cursor: pointer;
}

.upload-btn-wrapper input[type=file] {
    font-size: 16px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 30px;
}


/* ==================== */

.favouriteStores-box .favouriteStores-imgBox {
    width: 100%;
    height: 200px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.favouriteStores-box .shopLocation {
    color: #666666;
    font-size: 16px;
}

.favouriteStores-box .shopLocation svg {
    font-size: 20px;
}

.favouriteStores-box .ratingBox {
    position: static;
    width: 63px;
    padding: 3px 4px;
    left: 0px;
    top: 68px;
    border: 1px solid #383A48;
    box-sizing: border-box;
    box-shadow: 1px 2px 4px rgba(102, 102, 102, 0.25);
}

.favouriteStores-box .ratingBox.ratingBox-green {
    background: #00CA84;
}

.favouriteStores-box .ratingBox.ratingBox-yellow {
    background: #F7B600
}

.favouriteStores-box .ratingBox.ratingBox-red {
    background: #F1657C;
}

.favouriteStores-box .rating {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    color: #FFFFFF;
}

.favouriteStores-box .rating svg {
    color: white;
    font-size: 20px;
}

.favouriteStores-box .rating p {
    padding-top: 3px;
}


/* =========== user section css =================================== */

.customer-section {
    margin: 40px 40px 40px 40px;
}

.dashboard-section {
    margin: 40px 40px 40px 40px;
}

.store-section {
    margin: 40px 40px 40px 40px;
}

.adsSection {
    margin: 40px 40px 40px 40px;
}

.analytics-section {
    margin: 40px 40px 40px 40px;
}

.promotions-section {
    margin: 40px 40px 40px 40px;
}

.manageOrder-section {
    margin: 40px 40px 40px 40px;
}

.addUserAccess-section {
    margin: 40px 40px 40px 40px;
}

.addProduct-section {
    background: #FAFAFA;
    padding-bottom: 40px;
}


/* =========== Marketing css =================================== */

.apply-promotion-tableBox {
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.apply-promotion-tableBox .rounded-paraTable {
    max-height: 360px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.apply-promotion-tableBox .rounded-paraTable::-webkit-scrollbar {
    display: none;
}

.apply-promotion-tableBox .apply-promotion-productBox {
    background-color: #FFFFFF;
}

.apply-promotion-tableBox .apply-promotion-row-selected {
    background-color: #F5F5F5;
    width: 100%;
}
.apply-promotion-row-selected .ant-checkbox-wrapper .ant-checkbox {
    top: -12px;
    left: 9px;
}
.apply-promotion-row-selected .ant-checkbox .ant-checkbox-inner{

    width: 20px;
    height: 20px;
    border-radius: 4px;
   
}
/* .table-promotion-row .ant-checkbox-wrapper span{
    width: 100%;
    display: inline-table;
} */
.table-promotion-row .ant-checkbox-group{
    width: 100%;
}
/* =============================== */

.categories-sectionBox {
    margin: 40px 40px 40px 40px;
}

.categories-box {
    /* padding: 0px 25px; */
    background: #fff;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.categories-table {
    border: 1px solid #E6E6E6;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.categories-table table {
    margin-top: -1px;
    margin-bottom: 0px;
}

.category-requests-box {
    padding: 14px 20px;
    margin-top: 40px;
    background: #FAFAFA;
    border: 1px solid #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}

.find-category-drawer .home_inputsearchbox input {
    text-transform: capitalize;
}

.find-category-drawer .home_inputsearchbox input {
    text-transform: capitalize;
    font-style: normal;
}

.btnPara-sms {
    padding: 6px 10px;
    font-family: 'Gotham Rounded';
    color: #999999;
    font-style: normal;
    font-weight: normal;
    background: #F2F2F2;
    border: 1px solid #F2F2F2;
}


/* ======================================== */


/* ===== product page  =============== */

.product-search-box .input-group-text {
    border-radius: 8px 0px 0px 0px;
    border-top: 1px solid #E6E6E6;
    border-right: 1px solid #E6E6E6;
    border-bottom: 1px solid #E6E6E6;
    -webkit-border-radius: 8px 0px 0px 0px;
    -moz-border-radius: 8px 0px 0px 0px;
    -ms-border-radius: 8px 0px 0px 0px;
    -o-border-radius: 8px 0px 0px 0px;
}

.product-search-box select.form-control {
    height: 58px;
    color: #A6A6A6;
    border: 1px solid #E6E6E6;
    border-left: 1px solid #fff;
    border-radius: 0px 8px 0px 0px;
    -webkit-border-radius: 0px 8px 0px 0px;
    -moz-border-radius: 0px 8px 0px 0px;
    -ms-border-radius: 0px 8px 0px 0px;
    -o-border-radius: 0px 8px 0px 0px;
}

.product-search-box input.form-control.product-search {
    height: 58px;
    color: #A6A6A6;
    border: 1px solid #E6E6E6;
    border-left: 1px solid #fff;
    border-radius: 0px;
}

.product-filter-box {
    border: 1px solid #E6E6E6;
    border-top: 0px solid #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    border-radius: 0px 0px 8px 8px;
}

.product-filter-box .btn {
    font-size: 16px;
    outline: none;
    color: #00CA84;
}

.product-filter-box .btn:active,
.product-filter-box .btn:focus {
    outline: none;
    box-shadow: none
}

.product-filter-box svg {
    font-size: 20px;
    color: #00CA84;
}


/* ================================= */

.addProduct-mainHeadingBox {
    padding: 24px 0px;
    background: #F3F5E6;
    margin-bottom: 40px;
}

.addProduct-Box {
    padding: 40px 32px;
    margin: 40px 40px;
    background: #fff;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.addProduct-Box .addProduct-heading {
    color: #A6A6A6;
    font-size: 18px;
    margin-bottom: 20px;
}

.addProduct-Box label {
    color: #333333;
    font-size: 18px;
    margin-bottom: 0px;
    font-family: 'Gotham Rounded';
    font-style: normal;
}

.addProduct-Box label.custom-control-label {
    color: #333333;
    font-size: 16px;
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 300;
}

label span {
    color: red;
    font-size: 18px;
}
.ManageRoles label span{
    color: #131313 !important;
}

.addProduct-Box .label-subText {
    color: #A6A6A6;
    margin-top: 0px;
    font-size: 16px;
}


/* ============================ */

.btn-outline-dark-whiteBg {
    background-color: #ffffff;
}