.businesses-with-para {
    margin-top: 118px;
}

.businesses-with-para .heading {
    font-family: 'Gotham Rounded';
    font-weight: 400;
    font-style: bold;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
}

.businesses-with-para .companies_box {
    padding-top: 40px;
    margin-top: 32px;
    background-color: rgba(250, 250, 250, 1);
    box-shadow: 0px 1px 1px 0px rgba(102, 102, 102, 0.25);
}

#button {
    color: white;
    font-size: 16px;
    height: 44px;
    width: 265px;
    border-radius: 24px;
    background-color: #383A48;
    border: 0px;
    font-weight: 400;
    font-family: 'Gotham rounded';
}