.saveforlater {
    font-size: 16px;
    cursor: pointer;
}

.backbutton {
    border-radius: 4px;
    width: 72px;
    height: 44px;
    border: 1px rgba(56, 58, 72, 1) solid;
}

.nextbutton1 {
    background-color: #00CA84;
    border: 1px black solid;
    width: 102px;
    height: 44px;
    border-radius: 4px;
    margin-left: 22px;
}

.label {
    font-family: 'Gotham Rounded';
    font-weight: 400;
    line-height: 19px;
}