/* .root {
    margin-top: 106px;
} */

.para-for-everyone {
    margin-top: 106px;
}

.feature1_topText {
    font-weight: 400;
    font-style: normal;
    font-size: 32px;
    line-height: 38.4px;
    color: black;
    padding-left: 4%;
    padding-right: 4%;
}

.feature1_bottomText {
    font-size: 18px;
    line-height: 21.6px;
    color: 'black';
    padding-left: 4%;
    padding-right: 4%;
    padding-top: 1%;
}

.box1 {
    margin-left: 220px;
}

.feature2_topText {
    font-weight: 400;
    font-style: normal;
    font-size: 32px;
    line-height: 38.4px;
    color: black;
    padding-left: 4%;
    padding-right: 4%;
}

.feature2_bottomText {
    font-size: 18px;
    line-height: 21.6px;
    color: 'black';
    padding-left: 4%;
    padding-right: 4%;
    padding-top: 1%;
}

@media (max-width:1140px) {
    .content-to-hide {
        display: none;
    }
}

.blueText {
    color: #5D33FF
}

#button {
    color: white;
    font-size: 16px;
    height: 44px;
    width: 265px;
    border-radius: 24px;
    background-color: #383A48;
    border: 0px;
    font-weight: 400;
    font-family: 'Gotham rounded';
}