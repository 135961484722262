@import "antd/dist/antd.css";
@import "./assets/fonts/fontfamily.css";
@import "./assets/css/variables.css";

body {
    color: var(--custom-main-btn-secondary);
    margin: 0;
    font-family: "Gotham Rounded";
    font-size: 16px;
    /* font-family: 'Gotham'; */
}

a:hover {
    color: var(--custom-main-btn-primary);
}

/* textarea:focus, */

/* input[type="search"]:focus, */

/* 
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
    border-color: rgb(0, 202, 132);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(0, 202, 132, 0.6);
    outline: 0 none;
} */

.fontFamily-Gotham {
    font-family: "Gotham";
}

/* 
.bgWhiteSmoke {
    background-color: #FAFAFA;
} */

.greyBox {
    background-color: #f2f2f2;
}

.bgLightGrey {
    background-color: #f9f9f9;
}

.bgGrey {
    background-color: #999999;
}

.bgLightDark {
    background-color: #666666;
}

.bgDark {
    background-color: #333;
}

.bgGreen {
    background-color: var(--custom-main-btn-primary);
}

.bgLightGreen {
    background: rgba(0, 171, 141, 0.1);
}

.text-ParaGrey,
.greyText {
    color: #999999;
}

.text-lightDark {
    color: #666666;
}

.text-paraDark,
.darkText {
    color: #333;
}

.text-paraGreen,
.greenText {
    color: var(--custom-main-text-primary);
}

.text-paraRed,
.redText {
    color: #ff4747;
}

.text-paraYellow,
.yellowText {
    color: #f7a300;
}

.textOrange {
    color: #f2994a;
}

.textUnderline {
    text-decoration: underline;
}

.badgeRed {
    padding: 8px;
    color: #ff5858;
    background: rgba(255, 88, 88, 0.1);
    border-radius: 4px;
}

.badgeGreen {
    padding: 8px;
    color: var(--custom-main-btn-primary);
    background: rgba(0, 171, 141, 0.1);
    border-radius: 4px;
}

.ft-size12 {
    font-size: 12px;
}

.ft-size13 {
    font-size: 13px;
}

.ft-size14 {
    font-size: 14px;
}

.ft-size15 {
    font-size: 15px;
}

.ft-size16 {
    font-size: 16px;
}

.ft-size18 {
    font-size: 18px;
}

.ft-size20 {
    font-size: 20px;
}

.mt-80-spacing {
    margin-top: 80px;
}

.fw-200 {
    font-weight: 200;
}

.fw-300 {
    font-weight: 300;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 800;
}

.fw-900 {
    font-weight: 900;
}

.lh-1 {
    line-height: 1;
}

.lh-sm {
    line-height: 1.25;
}

.lh-base {
    line-height: 1.5;
}

.lh-lg {
    line-height: 2;
}

/* ----------- */

.alert-para-primary {
    color: var(--custom-main-btn-primary);
    background: rgba(0, 202, 132, 0.1);
    border-radius: 4px;
}

.alert-para-primary.flash-sale {
    color: var(--custom-main-bg-primary);
    background: rgba(0, 202, 132, 0.1);
    border-radius: 4px;
}

/* ----------- */

@media (max-width: 991.98px) {
    .mt-80-spacing {
        margin-top: 40px;
    }
}

ul.list-styledPara {
    list-style: none;
    padding-left: 0;
}

ul.list-styledPara li::before {
    content: "\2022";
    color: #00ae8b;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: 0em;
}

.border-grey {
    border: 1px solid #e6e6e6;
}

.para-link-white:hover {
    color: #ffffff;
    text-decoration: underline;
}

.para-link-white:hover {
    color: var(--custom-main-text-primary);
    text-decoration: underline;
}

.para-link-green {
    color: var(--custom-main-text-primary);
    text-decoration: underline;
}

.para-link-green:hover {
    color: var(--custom-main-text-primary);
    text-decoration: underline;
}

.para-link-red {
    color: #ff4545;
    text-decoration: underline;
}

.para-link-red:hover {
    color: #ff4545;
    text-decoration: underline;
}

.para-link-yellow {
    color: #f7a300;
    text-decoration: none;
}

.para-link-yellow:hover {
    color: #f7a300;
    text-decoration: underline;
}

.para-link-dark {
    color: var(--custom-main-btn-secondary);
    text-decoration: none;
}

.para-link-dark:hover {
    color: var(--custom-main-btn-secondary);
    text-decoration: underline;
}

.clickable {
    cursor: pointer;
}

.form-control {
    height: calc(1.5em + 0.75rem + 4px);
    padding: 0.375rem 0.75rem;
}

.form-control::placeholder {
    color: #999999;
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: #999999;
}

.form-control ::-ms-input-placeholder {
    color: #999999;
}

.form-control.placeholder-italic::placeholder {
    color: #999999;
    opacity: 1;
    font-style: italic;
}

.form-control.placeholder-italic:-ms-input-placeholder {
    color: #999999;
    font-style: italic;
}

.form-control.placeholder-italic ::-ms-input-placeholder {
    color: #999999;
    font-style: italic;
}

.card.card-para {
    border: 1px solid #e6e6e6;
    border-radius: 8px;
}

/* .card.card-para.list-group-item {
    position: relative;
    display: block;
    padding: .75rem 1.25rem;
    border: 1px solid #E6E6E6;
    border-radius: 8px;
    -webkit-border-radius:;
    -moz-border-radius:;
    -ms-border-radius:;
    -o-border-radius:;
} */

.card.card-para .list-group-item:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.card.card-para .list-group-item:last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top: 1px solid #e6e6e6;
    box-sizing: border-box;
}

.navbar-address-selectBox {
    max-width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}



.SelectAddress {
    width: 220px;
}




.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 18px;
    font-size: 11px;
}



.navbar-address-selectBox .form-control {
    background-color: transparent;
    border: 0px;
    padding: 0px;
    padding-right: 4px;
    height: 20px;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
}

.navbar-address-selectBox .form-control:focus {
    background-color: transparent;
    border-color: none;
    box-shadow: none;
    outline: 0 none;
}

/* ========== And Design CSS  ============ */

.ant-notification {
    z-index: 99999 !important;
}

.ant-form {
    font-size: 16px;
}

.ant-form label {
    font-size: 16px;
}

.ant-input {
    width: 100%;
    /* height: calc(1.5em + .75rem + 2px); */
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: all 0.3s;
}

.ant-input:hover {
    border-color: var(--custom-main-btn-primary);
}

.ant-input:focus,
.ant-input-focused {
    border-color: var(--custom-main-btn-primary);
    box-shadow: 0 0 0 2px rgb(0, 202, 132, 0.2);
    border-right-width: 1px !important;
    outline: 0;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: var(--custom-main-btn-primary);
    border-right-width: 1px !important;
    z-index: 1;
}

.ant-input-affix-wrapper {
    font-size: 1rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    /* height: calc(1.5em + .75rem + 2px); */
    padding: 0.375rem 0.75rem;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.275rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-switch-checked {
    background-color: var(--custom-main-btn-primary);
}

span.ant-radio+* {
    color: var(--custom-main-btn-secondary);
    font-weight: 400;
    font-size: inherit;
}

.ant-radio-checked .ant-radio-inner {
    border-color: var(--custom-main-btn-primary);
}

.ant-radio-inner::after {
    background-color: var(--custom-main-btn-primary);
}

.ant-radio-checked::after {
    border: 1px solid var(--custom-main-btn-primary);
}

.ant-rate {
    color: var(--custom-main-btn-primary);
}

.MuiRating-icon {
    color: var(--custom-main-btn-primary);
}

.add-review-box .MuiRating-icon {
    font-size: 24px;
}

.shopDetails-allRating-box .MuiRating-icon {
    font-size: 24px;
}

.shopDetails-allRating-box {
    height: 25px;
}

.shopDetails-allRating-box label {
    margin-bottom: 0px;
}

.ant-upload.ant-upload-drag {
    border-radius: 8px;
    padding: 20px;
}

.ant-drawer {
    position: fixed;
    z-index: 1111;
}

/* ======= Sweet Alter ================================== */

.swal2-container {
    z-index: 99999 !important;
}

.swal2-title {
    margin: 0;
    padding: 0.8em 1em 0;
    color: #595959;
    font-size: 20px;
    font-weight: 500;
}

.swal2-html-container {
    margin: 1em 1.6em 0.3em;
    padding: 0;
    overflow: auto;
    color: #545454;
    font-size: 16px;
    font-weight: 400;
}

.swal2-icon .swal2-icon-content {
    display: flex;
    align-items: center;
    font-size: 2.75em;
}

/* ===== SignIn Buttons ================================= */

.MuiButtonBase-root.btn-SignIn,
.ant-btn.btn-SignIn {
    font-size: 12px;
    border: 0px;
    color: var(--custom-main-btn-secondary);
    background-color: rgba(0, 202, 132, 0.1);
    border-radius: 24px;
    font-family: "Gotham Rounded";
    font-size: 14px;
    height: 44px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    -ms-border-radius: 24px;
    -o-border-radius: 24px;
}

.MuiButtonBase-root.btn-SignIn:hover,
.ant-btn.btn-SignIn:hover {
    text-decoration: none;
    border: 1px solid var(--custom-main-btn-primary);
    background-color: var(--custom-main-btn-primary);
    color: white;
}

.MuiButtonBase-root.btn-SignIn svg,
.ant-btn.btn-SignIn svg {
    color: var(--custom-main-text-primary);
    /* background-color: #9ea1a0; */
}

.MuiButtonBase-root.btn-SignIn:hover svg,
.ant-btn.btn-SignIn:hover svg {
    color: 00ca84;
    background-color: #ffffff;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

/* ===== Buttons ================================= */

.ant-btn {
    /*line-height: 1.5715;
    */
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: manipulation;
    height: auto;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    -webkit-border-radius: 0.25rem;
    -moz-border-radius: 0.25rem;
    -ms-border-radius: 0.25rem;
    -o-border-radius: 0.25rem;
}

.ant-btn .anticon {
    vertical-align: 0;
}

.anticon {
    vertical-align: 0;
}

/* ----- Ant form large input ---------- */

.ant-select-lg .ant-select-selection-overflow {
    margin-top: -10px;
}

.ant-select-lg .ant-select-selection-overflow .anticon svg {
    display: inline-block;
    font-size: 14px;
    overflow: hidden;
    vertical-align: inherit;
}

.ant-select-lg input.form-control,
.ant-select-lg select {
    height: calc(1.5em + 1.2rem + 4px);
}

.ant-select-lg .ant-input-affix-wrapper {
    height: calc(1.5em + 1.2rem + 4px);
}

.ant-select-lg .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    width: 100%;
    height: calc(1.5em + 1.2rem + 4px);
    padding: 0.575rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}

.ant-select-lg .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    height: calc(1.5em + 1.2rem + 0px);
    /* height: calc(1.5em + 1.2rem + 4px); */
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--custom-main-btn-primary);
    border-color: var(--custom-main-btn-primary);
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: var(--custom-main-btn-primary);
}

/* ---- Button colors --------- */

.btnPara-primary {
    font-family: "Gotham Rounded";
    color: var(--custom-main-btn-secondary);
    font-style: normal;
    font-weight: normal;
    background: var(--custom-main-btn-primary);
    border: 1px solid var(--custom-main-btn-secondary);
}

.btnPara-primary:hover {
    color: var(--custom-main-btn-secondary);
    background: var(--custom-main-btn-primary);
    border: 1px solid var(--custom-main-btn-secondary);
}

.btnPara-red {
    font-family: "Gotham Rounded";
    color: #ffffff;
    font-style: normal;
    font-weight: normal;
    background: #fd6e6e;
    border: 1px solid #999999;
}

.btnPara-red:hover {
    color: #ffffff;
    background: #fd6e6e;
    border: 1px solid #999999;
}

.btnPara-dark {
    font-family: "Gotham Rounded";
    color: #ffffff;
    font-style: normal;
    font-weight: normal;
    background: var(--custom-main-btn-secondary);
    border: 1px solid #999999;
}

.btnPara-dark:hover {
    color: #ffffff;
    background: var(--custom-main-btn-secondary);
    border: 1px solid #999999;
}

.btnPara-darkness {
    font-family: "Gotham Rounded";
    color: #ffffff;
    font-style: normal;
    font-weight: normal;
    background: var(--custom-main-btn-primary);
    border: 1px solid #383a48;
}

.btnPara-darkness:hover {
    color: #ffffff;
    background: var(--custom-main-btn-secondary);
    border: 1px solid #383a48;
}

.btn-show-more {
    font-family: "Gotham Rounded";
    /* color: #ffffff; */
    color: var(--custom-main-btn-secondary);
    font-style: normal;
    font-weight: normal;
    background: var(--custom-main-btn-primary);
    border: 1px solid #383a48;
}

.btn-show-more:hover {
    color: #ffffff;
    background: var(--custom-main-btn-secondary);
    border: 1px solid #383a48;
}

.btnPara-white {
    color: #333;
    font-family: "Gotham Rounded";
    text-align: center;
    border: 1px solid var(--custom-main-btn-secondary);
    background-color: #ffffff;
}

.btnPara-white:hover {
    color: #ffffff;
    background: var(--custom-main-btn-primary);
    border: 1px solid var(--custom-main-btn-secondary);
}

/* ---- Button Border outline --------- */

.btn-outline-primary {
    color: var(--custom-main-btn-secondary);
    font-family: "Gotham Rounded";
    text-align: center;
    font-style: normal;
    font-weight: normal;
    border: 1px solid #383a48;
    background-color: transparent;
}

.btn-outline-primary:hover {
    color: #ffffff;
    background: var(--custom-main-btn-primary);
    border: 1px solid var(--custom-main-btn-secondary);
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background: var(--custom-main-btn-primary);
    border-color: var(--custom-main-btn-secondary);
}

.btn-outline-red {
    color: #ff5858;
    font-family: "Gotham Rounded";
    text-align: center;
    font-style: normal;
    font-weight: normal;
    border: 1px solid #ff5858;
    background-color: transparent;
}

.btn-outline-red:hover {
    color: #ffffff;
    background: #ff5858;
    border: 1px solid var(--custom-main-btn-secondary);
}

.btn-outline-green {
    color: var(--custom-main-btn-primary);
    font-family: "Gotham Rounded";
    text-align: center;
    font-style: normal;
    font-weight: normal;
    border: 1px solid var(--custom-main-btn-primary);
    background-color: transparent;
}

.btn-outline-green:hover {
    color: #ffffff;
    background: var(--custom-main-btn-primary);
    border: 1px solid var(--custom-main-btn-secondary);
}

.btn-outline-white {
    color: #ffffff;
    font-family: "Gotham Rounded";
    text-align: center;
    font-style: normal;
    font-weight: normal;
    border: 1px solid #ffffff;
    background-color: transparent;
}

.btn-outline-white:hover {
    color: #ffffff;
    background: var(--custom-main-btn-primary);
    border: 1px solid var(--custom-main-btn-secondary);
}

.btn-outline-dark {
    color: #333;
}

/* ---- Button BorderLess --------- */

.btn-borderless-green {
    font-family: "Gotham Rounded";
    text-align: center;
    color: var(--custom-main-text-primary);
    background: transparent;
    border: 0px solid var(--custom-main-btn-secondary);
}

.btn-borderless-green:hover {
    color: #ffffff;
    background: #00aa6f;
    border: 0px solid #00aa6f;
}

.btn-borderless-red {
    font-family: "Gotham Rounded";
    user-select: none;
    color: #ff4747;
    background: transparent;
    border: 0px solid var(--custom-main-btn-secondary);
}

.btn-borderless-red:hover {
    color: #ffffff;
    background: #ff4747;
    border: 0px solid #ff4747;
}

.btn-borderless-black {
    font-family: "Gotham Rounded";
    user-select: none;
    color: var(--custom-main-btn-secondary);
    background: transparent;
    border: 0px solid var(--custom-main-btn-secondary);
}

.btn-borderless-black:hover {
    color: #ffffff;
    background: #00aa6f;
    border: 0px solid #00aa6f;
}

.btn-borderless-white {
    font-family: "Gotham Rounded";
    user-select: none;
    color: #ffffff;
    background: transparent;
    border: 0px solid var(--custom-main-btn-secondary);
}

.btn-borderless-white:hover {
    color: #ffffff;
    background: transparent;
    border: 0px solid #ffffff;
}

.btnPara-primary.btnPara-withSvg,
.btnPara-dark.btnPara-withSvg {
    padding: 10px 16px;
}

.btnPara-withSvg {
    padding: 10px 16px;
}

.btnWithSvg {
    position: relative;
    padding-right: 35px;
}

.btnWithSvg svg {
    position: absolute;
    top: 12px;
    margin-left: 5px;
}

.btn-group-md>.btn,
.btn-md {
    padding: 0.563rem 1rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

.btn-view-order {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    color: #2e8eff;
}

.btn-uploadLogo {
    color: #f8f9fa;
    border-radius: 24px;
    font-family: "Gotham Rounded";
    margin-left: 24px;
    background-color: rgba(56, 58, 72, 1);
}

.btn-uploadLogo:hover {
    color: #f8f9fa;
    background-color: rgba(56, 58, 72, 1);
}

.btn-view-order:hover {
    color: #ffffff;
    background-color: #2e8eff;
}

.btn-rounded {
    border-radius: 42px;
    -webkit-border-radius: 42px;
    -moz-border-radius: 42px;
    -ms-border-radius: 42px;
    -o-border-radius: 42px;
}

.rounded-8 {
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

/* ========== custom-radio ================================ */

.custom-control.custom-radio label.custom-control-label::before {
    position: absolute;
    top: 0.3rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    border: #adb5bd solid 1px;
}

.custom-control.custom-radio label.custom-control-label::after {
    position: absolute;
    top: 0.3rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat;
}

.custom-control.custom-radio .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #00ae8b;
    background-color: #00ae8b;
}

/* ========== custom-check ================================ */

.custom-control.custom-checkbox label.custom-control-label::before {
    position: absolute;
    top: 0.3rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    border: #adb5bd solid 1px;
}

.custom-control.custom-checkbox label.custom-control-label::after {
    position: absolute;
    top: 0.3rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat;
}

.custom-control.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #00ae8b;
    background-color: #00ae8b;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

/*---  custom Para Checkbox -----*/

.custom-paraCheckbox {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.custom-paraCheckbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.custom-paraCheckbox .paraCheckbox-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background: #ffffff;
    border: 1px solid #999999;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.custom-paraCheckbox.custom-paraCheckbox-rounded .paraCheckbox-checkmark {
    /*-- Checkbox  rounded ---*/
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background: #ffffff;
    border: 1px solid #999999;
    border-radius: 50%;
}

.custom-paraCheckbox:hover input~.paraCheckbox-checkmark {
    background-color: #f2f2f2;
}

.custom-paraCheckbox input:checked~.paraCheckbox-checkmark {
    background-color: var(--custom-main-btn-primary);
    border: 1px solid var(--custom-main-btn-primary);
}

.custom-paraCheckbox .paraCheckbox-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.custom-paraCheckbox input:checked~.paraCheckbox-checkmark:after {
    display: block;
}

.custom-paraCheckbox .paraCheckbox-checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(43deg);
}

/* ------- custom Para Radio Box ----- */

.custom-paraRadiobox {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.custom-paraRadiobox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.paraRadiobox-Checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background: #ffffff;
    border: 1px solid #999999;
    border-radius: 50%;
}

.custom-paraRadiobox:hover input~.paraRadiobox-Checkmark {
    background-color: #f2f2f2;
}

.custom-paraRadiobox input:checked~.paraRadiobox-Checkmark {
    background-color: var(--custom-main-btn-primary);
    border: 1px solid var(--custom-main-btn-primary);
}

.paraRadiobox-Checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.custom-paraRadiobox input:checked~.paraRadiobox-Checkmark:after {
    display: block;
}

.custom-paraRadiobox .paraRadiobox-Checkmark:after {
    top: 6px;
    left: 5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

/* ================= custom-switch ============================== */

.custom-control.custom-switch {
    padding-left: 3.25rem;
}

.custom-control.custom-switch .custom-control-label {
    padding-top: 3px;
}

.custom-control.custom-switch .custom-control-label::before {
    top: 0.15rem;
    left: -3.25rem;
    width: 40px;
    height: 24px;
    pointer-events: all;
    border-radius: 32px;
    background-color: #adb5bd;
}

.custom-control.custom-switch .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: var(--custom-main-btn-primary);
    background-color: var(--custom-main-btn-primary);
}

.custom-control.custom-switch .custom-control-label::after {
    top: calc(0.15rem + 4px);
    left: calc(-3.25rem + 6px);
    width: calc(1rem - 0px);
    height: calc(1rem - 0px);
    background-color: #ffffff;
    border-radius: 0.5rem;
}

/* ======================================================= */

.blackBox-heading {
    color: #fff;
    padding: 12px 18px;
    margin-bottom: 32px;
    background: #000000;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.blackBox-heading .btn {
    color: #fff;
    border-radius: 8px;
}

.blackBox-heading .btn:hover {
    color: #fff;
    border-radius: 8px;
}

/* ======================================================= */

.rounded-paraTable {
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.rounded-paraTable table {
    margin-top: -1px;
    margin-bottom: 0px;
}

/* ========== search box =================================== */

/* Styles for wrapping the search box */

/* .has-searchBox {
    width: 1000%;
    margin: 50px auto;
} */

/* Bootstrap 4 text input with search icon */

.has-searchBox .has-search .form-control {
    padding: 27px 51px;
    padding-left: 3rem;
    font-size: 16px;
}

.has-searchBox .has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 20px;
    height: 20px;
    text-align: center;
    pointer-events: none;
    margin-top: 20px;
    margin-left: 15px;
}

.paraSearchBox .searchBox {
    display: flex;
    justify-content: space-between;
    height: 48px;
    background: #ffffff;
    border: 1px solid #383a48;
    box-shadow: 0px 2px 4px rgba(51, 51, 51, 0.25);
    border-radius: 4px;
}

.paraSearchBox .searchBox svg {
    margin: 12px 12px 11px 16px;
    color: var(--custom-main-btn-primary);
}

.paraSearchBox .inputSearchBox {
    font-family: "Gotham Rounded";
    font-style: italic;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    color: #999999;
    margin-left: 0px;
    flex: 1 1;
    align-content: center;
}

.paraSearchBox .searchButton {
    font-family: "Gotham Rounded";
    align-items: center;
    padding: 11px 16px;
    background: var(--custom-main-btn-primary);
    border: 1px solid #383a48;
    border-radius: 4px;
    color: white;
    margin: -1px;
}

/* ========== modal  =================================== */

.modal-header.modal-header-center {
    justify-content: center;
}

.modal-header.modal-header-center button.close {
    position: absolute;
    right: 18px;
}

/* ========== modal right side =================================== */

.paraRounded-table {
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.paraRounded-table table {
    margin-top: -1px;
    margin-bottom: 0px;
}

.table-paraDark {
    background-color: #fafafa;
}

/* ========== para Tabs =================================== */

.paraTabs .nav-tabs {
    border-bottom: 0px solid #dee2e6;
    /* border-bottom: 1px solid transparent; */
}

.paraTabs .nav-tabs .nav-link {
    color: var(--custom-main-btn-secondary);
    margin-bottom: -1px;
    border-style: none;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    /* New */
    padding: 6px 0px;
    margin-right: 30px;
    /* border: 1px solid transparent; */
}

.paraTabs .nav-tabs .nav-item.show .nav-link,
.paraTabs .nav-tabs .nav-link.active {
    color: var(--custom-main-btn-primary);
    background-color: #fff;
    /* border-color: #ffffff #ffffff #14a978; */
    border-style: none;
    /* border: 1px solid transparent; */
    border-bottom: 2px solid var(--custom-main-btn-primary);
}

/* ========== increment-decrement-box =================================== */

.incrementDecrement-box {
    display: inline-flex;
    background-color: rgba(0, 171, 141, 0.1);
    padding: 0px 0px;
    border-radius: 4px;
    border: 1px solid black;
}

.incrementDecrement-box p {
    color: #00c78a;
    font-size: 16px;
    width: 20px;
    text-align: center;
    padding: 7px 0px 5px 0px;
    /* padding: 8px 0px 6px 0px; */
}

.incrementDecrement-box .btn-increment,
.incrementDecrement-box .btn-decrement {
    color: #911D21;
    font-size: 16px;
    font-weight: 500;
    background-color: transparent;
    border: none;
    padding: 10px 10px 8px 10px;
    /* padding: 12px 10px 10px 10px; */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition-duration: 0.4s;
    cursor: pointer;
    line-height: 0;
}

.incrementDecrement-box .btn-increment {
    color: #0F3325;
}

.incrementDecrement-box .btn-decrement {
    color: #666666;
}

.incrementDecrement-box .btn-increment svg,
.incrementDecrement-box .btn-decrement svg {
    font-size: 20px;
}

/* ========== modal right side =================================== */

/*left right modal*/

.modal.left_modal,
.modal.right_modal {
    position: fixed;
    z-index: 99999;
    background-color: rgb(0 0 0 / 25%);
}

.modal.left_modal .modal-dialog,
.modal.right_modal .modal-dialog {
    position: fixed;
    margin: auto;
    max-width: 450px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

.right_modal .modal-dialog,
.left_modal .modal-dialog {
    /* max-width: 100%; */
    margin: 1.75rem auto;
}

@media (min-width: 576px) {
    .left_modal .modal-dialog {
        max-width: 100%;
    }

    .right_modal .modal-dialog {
        max-width: 100%;
    }
}

.modal.left_modal .modal-content,
.modal.right_modal .modal-content {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh !important;
    border-radius: 0;
    border: none;
}

.modal.left_modal .modal-body,
.modal.right_modal .modal-body {
    padding: 15px 32px 30px;
}

@media (max-width: 991.98px) {

    .modal.left_modal .modal-body,
    .modal.right_modal .modal-body {
        padding: 15px 15px 30px;
    }
}

/* Close Problem */

/* .modal.left_modal {
    pointer-events: none;
    background: transparent;
} */

/*Left*/

.modal.left_modal.fade .modal-dialog {
    left: -50%;
    -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
    -o-transition: opacity 0.3s linear, left 0.3s ease-out;
    transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left_modal.fade.show .modal-dialog {
    left: 0;
    width: 100%;
    box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.5);
}

/*Right*/

.modal.right_modal.fade .modal-dialog {
    right: -50%;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right_modal.fade.show .modal-dialog {
    right: 0;
    width: 100%;
    box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.5);
}

/* ----- MODAL STYLE ----- */

.left_modal .modal-header .modal-content,
.right_modal .modal-header .modal-content {
    border-radius: 0;
    border: none;
}

.left_modal .modal-header,
.right_modal .modal-header {
    padding: 10px 15px;
    border-bottom-color: #eeeeee;
    background-color: #fafafa;
}

.modal_outer.left_modal .modal-body,
.modal_outer.right_modal .modal-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: 91vh;
}

.right_modal .modal-dialog.modal-dialog-scrollable,
.left_modal .modal-dialog.modal-dialog-scrollable {
    max-height: calc(100% - 0rem);
}

.right_modal .modal-dialog.modal-dialog-scrollable .modal-content,
.left_modal .modal-dialog.modal-dialog-scrollable .modal-content {
    max-height: calc(100% - 0rem);
}

/* =============================================================== */

.form-control {
    display: block;
    /* width: auto; */
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    /* color: #495057; */
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-input-icon {
    position: absolute;
    top: 12px;
    right: 12px;
    color: #666666;
}

.form-selectOption {
    display: block;
    width: auto;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    /* color: #495057; */
    background-color: #fff;
    background-clip: padding-box;
    border: 0px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-selectOption:focus,
.form-selectOption:focus-within {
    color: #495057;
    background-color: #fff;
    border-color: var(--custom-main-btn-primary);
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 171, 141, 0.25);
}

.form-selectOption.option-greenText:focus,
.form-selectOption.option-greenText:focus-within {
    color: var(--custom-main-btn-primary);
    background-color: #fff;
    border-color: var(--custom-main-btn-primary);
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 171, 141, 0.25);
}

.form-selectOption.option-redText:focus,
.form-selectOption.option-redText:focus-within {
    color: #ff5858;
    background-color: #fff;
    border-color: #ff5858;
    border-color: rgb(255, 88, 88);
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 88, 88, 0.25);
}

.form-selectOption.option-orangeText:focus,
.form-selectOption.option-orangeText:focus-within {
    color: #f2994a;
    background-color: #fff;
    border-color: #f2994a;
    border-color: rgb(242, 153, 74);
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(242, 153, 74, 0.25);
}

.option-greenText {
    color: var(--custom-main-btn-primary);
    /* background-color: rgba(0, 171, 141, 0.1); */
}

.option-redText {
    color: #ff5858 !important;
    /* background-color: antiquewhite; */
}

.option-orangeText {
    color: #f2994a !important;
    /* background-color: rgba(242, 153, 74, 0.1); */
}

.stepper-progressBar-box {
    max-width: 700px;
    margin: auto;
    background-color: #ffffff;
    padding: 0px;
    border-radius: 8px;
}

.stepper-progressBar {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.stepper-progressBar .progress-bar {
    background-color: var(--custom-main-btn-primary);
}

.business-logoImage {
    width: 104px;
    height: 104px;
    border-radius: 50%;
    background-color: #dee2e6;
}

.stepper-progressBar-box .MuiStepIcon-root.MuiStepIcon-active {
    color: var(--custom-main-btn-primary) !important;
}

.stepper-progressBar-box .MuiStepIcon-root.MuiStepIcon-completed {
    color: var(--custom-main-btn-primary) !important;
}

/* ================================== */

.loginFormModal .modal-content {
    border-radius: 24px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    -ms-border-radius: 24px;
    -o-border-radius: 24px;
}

.loginFormModal .modal-header {
    position: absolute;
    z-index: 99;
    right: 14px;
    top: 12px;
    border: 0;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    padding: 5px 10px;
}

.loginFormModal .modal-header .close {
    font-weight: 400;
    color: white;
    padding: 0rem 0rem;
    margin: 0;
    opacity: 1;
}

.adminLogin-box,
.userLogin-box {
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    max-width: 510px;
    margin: auto;
    border-radius: 24px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    -ms-border-radius: 24px;
    -o-border-radius: 24px;
}

.adminLogin-box .adminLogin-headerBox,
.userLogin-box .userLogin-headerBox {
    display: flex;
    height: 150px;
    padding: 20px;
    color: white;
    background-color: #00ae8b;
    border-radius: 24px 24px 0px 0px;
    -webkit-border-radius: 24px 24px 0px 0px;
    -moz-border-radius: 24px 24px 0px 0px;
    -ms-border-radius: 24px 24px 0px 0px;
    -o-border-radius: 24px 24px 0px 0px;
}

.adminLogin-box .adminLogin-formBox,
.userLogin-box .userLogin-formBox {
    padding: 20px;
    margin-top: 16px;
    max-height: 500px;
    overflow: auto;
}

.adminLogin-box .SignUpFlower-box,
.userLogin-box .SignUpFlower-box {
    position: absolute;
    right: 40px;
    top: 20px;
}

@media (max-width: 420px) {

    .adminLogin-box .adminLogin-headerBox,
    .userLogin-box .userLogin-headerBox {
        height: 115px;
    }

    .adminLogin-box .SignUpFlower-box img,
    .userLogin-box .SignUpFlower-box img {
        max-width: 180px;
    }
}

.socialNetwork-shareIcon {
    color: #666666;
    margin: 10px;
    width: 30px;
    height: 30px;
    border: 1px solid #666666;
    border-radius: 4px;
    text-align: center;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -ms-transition: 0.2s;
    -o-transition: 0.2s;
}

.socialNetwork-shareIcon:hover {
    color: #ffffff;
    border: 1px solid var(--custom-main-btn-primary);
    background-color: var(--custom-main-btn-primary);
}

.helpAccordion .MuiPaper-elevation1 {
    box-shadow: none;
}

.helpAccordion .MuiPaper-elevation1::before {
    display: none;
}

.helpAccordion .MuiAccordionSummary-content {
    margin: 6px 0px;
}

.helpAccordion .MuiAccordionDetails-root {
    display: flex;
    padding: 0px 0px 0px;
}

.helpAccordion .MuiAccordionSummary-root {
    padding: 0px;
}

.filter-box {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    border-radius: 0px 0px 8px 8px;
}

.filter-box .btn-filter {
    font-size: 16px;
    outline: none;
    color: var(--custom-main-btn-primary);
}

.filter-box .btn-filter:active,
.filter-box .btn-filter:focus {
    outline: none;
    box-shadow: none;
}

.filter-box svg {
    font-size: 20px;
    color: var(--custom-main-btn-primary);
}

/* ======= onCheck-colorBox  on click =========================================== */

.onCheck-colorBox {
    margin: 0px 4px 10px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.onCheck-colorBox-list {
    text-align: center;
    margin: 6px 0px 0px 0px;
    list-style-type: none;
    width: 100%;
}

.onCheck-colorBox [type="radio"] {
    display: none;
}

.onCheck-colorBox [type="radio"] {
    position: absolute;
    top: 10px;
    left: 20px;
}

.onCheck-colorBox .input-colorBorder+label {
    width: 100%;
    text-align: left;
}

.onCheck-colorBox .input-colorBorder+label>.colorBox-cardP {
    position: relative;
    width: 100%;
    height: auto;
    padding: 2px;
    border: 2px solid #dee2e6;
    background-color: #fafafa;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.colorBox-card {
    border: 2px solid var(--custom-main-btn-primary);
    padding: 2px;
}

/* ============================= */

.two-line-truncate {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.order-place-info .orderImage,
.past-orders-info .pastOrderImage {
    width: 128px;
    height: 108px;
    margin-right: 15px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

/* ========= Message =================== */

.chat-container {
    background: #fff;
    transition: 0.5s;
    border: 0;
    margin-bottom: 30px;
    position: relative;
    width: 100%;
    border: 1px solid #cecece;
    border-radius: 16px 16px 16px 16px;
    -webkit-border-radius: 16px 16px 16px 16px;
    -moz-border-radius: 16px 16px 16px 16px;
    -ms-border-radius: 16px 16px 16px 16px;
    -o-border-radius: 16px 16px 16px 16px;
}

.chat-container .people-listBox {
    height: 100%;
    max-height: 500px;
    overflow: auto;
}

.chat-container .people-listBox::-webkit-scrollbar-track,
.chat-container .message-data::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(184, 184, 184);
    -webkit-box-shadow: inset 0 0 6px rgba(184, 184, 184);
    border-radius: 10px;
    background-color: #f5f5f5;
    color: rgb(184, 184, 184);
}

.chat-container .people-listBox::-webkit-scrollbar,
.chat-container .message-data::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}

.chat-container .people-listBox::-webkit-scrollbar-thumb,
.chat-container .message-data::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(184, 184, 184);
    -webkit-box-shadow: inset 0 0 6px rgba(184, 184, 184);
    background-color: #b8b8b8;
}

.chat-container .people-list {
    border-right: 1px solid #cecece;
    height: 100%;
    position: relative;
    margin-bottom: 24px;
}

.chat-container .people-list .chat-list {
    border-radius: 16px 0px 0px 0px;
    -webkit-border-radius: 16px 0px 0px 0px;
    -moz-border-radius: 16px 0px 0px 0px;
    -ms-border-radius: 16px 0px 0px 0px;
    -o-border-radius: 16px 0px 0px 0px;
}

.chat-container .chat {
    border-left: 1px solid #cecece;
}

.select-messages {
    position: sticky;
    width: 100%;
    height: 58px;
    top: 0px;
    padding: 10px 15px;
    border-bottom: 1px solid #cecece;
    background: #f2fbf9;
    border-radius: 16px 0px 0px;
    -webkit-border-radius: 16px 0px 0px;
    -moz-border-radius: 16px 0px 0px;
    -ms-border-radius: 16px 0px 0px;
    -o-border-radius: 16px 0px 0px;
}

.select-messages .ant-select-selector {
    padding-left: 0px !important;
}

.people-list .chat-list .about-person {
    padding: 20px 15px 15px 15px;
    list-style: none;
    border-bottom: 1px solid #cecece;
}

.people-list .chat-list .about-person:hover {
    background: #fafafa;
    cursor: pointer;
}

.people-list .chat-list .about-person.active {
    background: #fafafa;
    position: relative;
}

.people-list .chat-list .about-person.active .name {
    font-weight: 600;
}

.people-list .chat-list .about-person .name {
    font-size: 15px;
    margin-bottom: 0px;
}

.people-list .about-person {
    padding-left: 8px;
}

.people-list .time {
    color: #999;
    font-size: 13px;
    margin-bottom: 0px;
}

.onselectedchatthred {
    background-color: #d1d9e1;
}

.onselectedchatthred:hover {
    background-color: #d1d9e1 !important;
}

.chat .chat-header {
    padding: 10px 15px;
    border-top: 0px solid #cecece;
    border-bottom: 1px solid #cecece;
    background: #f2fbf9;
    height: 58px;
}

/* 
.chat .chat-header .chat-about {
    float: left;
} */

.chat .chat-header .chat-about .person-name {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0px;
}

.chat .chat-header .chat-about .person-address {
    font-size: 12px;
    margin-bottom: 0px;
}

.message-data {
    padding: 10px 15px;
    min-height: 320px;
    max-height: 320px;
    overflow-y: auto;
}

.person-message {
    max-width: 400px;
}

.my-message {
    max-width: 400px;
}

.message-data .message-data-time {
    font-size: 10px;
    margin-bottom: 0px;
    margin-top: 10px;
}

.message-data .person-name,
.message-data .my-name {
    font-size: 12px;
    font-weight: 500;
}

.message-data .message.person-message {
    font-size: 14px;
}

.message-data .message.my-message {
    font-size: 14px;
}

.chat .chat-message {
    padding: 10px;
    border-top: 2px solid #00ae8b;
}

.chat .chat-message .preveiw-img {
    width: 55px;
    height: 55px;
    position: absolute;
    right: 32%;
    border: 1px solid #bdbdbd;
}

.chat .chat-message.shopmessages-content .ant-input {
    width: 530px;
}

.chat .chat-message .ant-input {
    border: none;
    padding: 0px;
    font-size: 14px;
    font-size: 14px;
    float: left;
    width: 394px;
    height: 67px;
    padding: 0 10px;
    color: #343a40;
    border: 0;
    padding: 10px;
    outline: none;
    background-color: #fafafa;
    @include font: ;
    border-radius: 12px;
}

.chat .chat-message .ant-input:focus,
.chat .chat-message .ant-input:hover,
.chat .chat-message .ant-input-focused {
    box-shadow: none;
    border: none;
}

.chat .chat-message .chat-msg-contain {
    display: flex;
    align-items: center;
    background-color: #fafafa;
}

.chat .chat-send {
    padding: 12px;
    background-color: #fafafa;
    border-radius: 0px 0px 16px 0px;
    -webkit-border-radius: 0px 0px 16px 0px;
    -moz-border-radius: 0px 0px 16px 0px;
    -ms-border-radius: 0px 0px 16px 0px;
    -o-border-radius: 0px 0px 16px 0px;
}

.chat .chat-send .sendImage,
.chat .chat-send .sendFile {
    width: 30px;
    height: 30px;
    padding: 4px;
    margin-left: 4px;
    color: #f2994a;
    background-color: #f2f2f2;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.chat .chat-send label span {
    color: var(--custom-main-btn-secondary);
}

.chat-badge-danger {
    padding: 4px 6px;
    font-size: 9px;
    letter-spacing: 1px;
    border-radius: 15px;
    color: #fff;
    background-color: #ff5858;
}

.title-active-line {
    background-color: var(--custom-main-btn-primary);
    position: absolute;
    width: 4px;
    height: 50%;
    top: 20px;
    left: 0;
    border-radius: 6px;
}

@media (max-width: 767px) {
    .chat-container .people-listBox {
        height: 100%;
        max-height: 250px;
        overflow: auto;
    }

    .chat .chat-header {
        padding: 10px 15px;
        border-top: 1px solid #cecece;
        border-bottom: 1px solid #cecece;
        background: #f2fbf9;
    }
}

.table-promotion-row .table-promotion-list {
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 15px;
    background: #f5f5f5;
}

.manage-order-product-view-deatil {
    background-color: #f9f9f9;
    padding: 11px;
    border-radius: 2px;
}

.shopFilter span {
    color: #000;
    font-size: 14px;
}

.shopFilter .ant-collapse-header {
    font-size: 16px;
    font-weight: bold;
}

.haderaddresopation .ant-select-open {
    width: fit-content !important;
}