.circle11 {
    width: 339px;
    height: 339px;
    border-radius: 100%;
    margin-top: -5%;
    margin-left: 80%;
    position: absolute;
    background-color: rgba(243, 245, 230, 1);
}

.circle22 {
    border-radius: 100%;
    width: 100%;
    height: 100%;
    right: -90%;
    bottom: 20%;
    background-color: rgba(243, 245, 230, 1);
}

.circle33 {
    width: 339px;
    height: 339px;
    border-radius: 100%;
    background-color: rgba(243, 245, 230, 1);
}

.ellipse1 {
    position: absolute;
    top: 0;
    right: 0
}

.ellipse2 {
    position: absolute;
    top: 150px;
    left: 0
}

.ellipse3 {
    position: absolute;
    top: 704px;
    left: 331px
}