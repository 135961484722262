.rootDiv{
    margin-top: 80px;
    margin-bottom: 100px;
}

.box11{
    margin-top: 54px;
    
    height: 721px;
}
.ellipse111{
    top:0;
    right: 0;
    position: absolute
}

.ellipse222{
    left:0;
    position: absolute;
    top:150px
}

.ellipse333{
    left:331px;
    position: absolute;
    top:784px
}

.textDiv{
    margin-top: 150px;
  
   
}

.hurrahText{
    font-weight: 400;
    font-style: normal;
    line-height: 40px;
    font-size: 40px;
   

}

.thumbsup{
    top:134px;
    right:365px;
    position: absolute;
}

.arrowdown{
    /* left: 137.89px; */
    left: 190px;
    top: 725px;
    position: absolute;

}

.key{
    left: 411px;
    top: 510px;
    position: absolute;

}

.moneyhand{
    left: 905px;
    top: 406px;
    position: absolute;

}

.box-border1{
     
    padding-top: 4%;
    padding-bottom: 4%;
     border-radius: 16px;
     border: 2px black solid;
    
     background-color: white;
     box-shadow: 1px 3px  rgba(102, 102, 102, 0.25);
     
 }

 .descText11{
     font-weight: 400;
     font-size: 18px;
     line-height: 25.2px;
     font-style: normal;
    
    
 }

 .buttonBrowsePara{
    color: white;
    font-size: 16px;
    height: 44px;
    width: 132px;
    border-radius: 24px;
    background-color: #383A48;
     border: 0px;
   
    font-weight: 400;
    font-family: 'Gotham rounded';
}

.browseParaButtonBox{
        margin-top: 15%;
}