.black-box {
    background-color: #333333;
    padding: 32px 0px;
}

hr.greenLine {
    position: relative;
    width: 96px;
    height: 4px;
    background: #00AE8B;
}

.delivery-order-list {
    padding: 40px 0px;
}
.addOrderHide{
    display: none;
}
.addOrderShow{
    display: block;
}
/* ======================== */

.addYourOrder-box .addYourOrder-imgBox {
    width: 100%;
    height: 200px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.addYourOrder-box .btn-addYourOrder {
    /* font-family: 'Gotham Rounded'; */
    /* font-style: normal; */
    /* font-weight: normal; */
    font-size: 16px;
    color: #666666;
}