.contact {
    margin-top: 60px;

}

.contact-info {
    /* background-image: url("./image/map.svg"); */
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 200px;
    background-color: #227855;
    padding: 40px;
    border-radius: 20px;
    position: relative;

}
.particalContact{
    position: absolute;
    bottom: -9px;
    right: 23px;
    transform: rotate(90deg);
}
.form-control {
    border: none !important;
    padding: 15px;
    background-color: #fff;
    outline: 0;
    box-shadow: none;
    border-bottom: 2px solid rgb(204, 204, 215) !important
}


.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #8bbafe;
    outline: 0;
    box-shadow: none;
    border-radius: 0px;
    border-bottom: 2px solid rgb(204, 204, 215) !important
}

h3 {
    font-size: 23px;
    margin: 0 0 10px;
}
@media only screen and (max-width: 768px) {
.contact-form{
margin-top: 20px;
}
}
