.main-footer {
    background-color: rgba(242, 242, 242, 1);
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
    bottom: 0;
    width: 100%;
    /* margin-top: 40px; */
}

.colHeading {
    font-family: 'Gotham Rounded';
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 15%;
    color: black;
    margin-bottom: 12px;
}

.listItem {
    font-family: 'Gotham Rounded';
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    color: rgba(153, 153, 153, 1);
    margin-top: 12px;
}

.footer-box {
    margin: 25px 0px;
}